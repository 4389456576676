import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageSheet } from 'components/_layouts/PageSheet';
import { Button, ButtonVariant } from 'components/Button';
import { Divider } from 'components/Divider';
import { PageError } from 'components/ErrorMessage';
import { Loader, LoaderSize } from 'components/Loader';
import { useAuthority } from 'store/authority';
import { LibraryDocument, TagWithCountType, useUpdateLibraryFileTagsMutation } from 'store/graphql';
import { MaterialTagComp } from './MaterialTag';
import s from './UpdateMaterialTags.module.scss';

export interface MaterialTag extends Pick<TagWithCountType, 'id' | 'name' | 'colorHex'> {
  attached?: boolean;
}

export interface UpdateTagsProps {
  materialTags: MaterialTag[];
  materialId: number;
  redirectUrl?: string;
}

export function UpdateMaterialTags({ materialId, materialTags, redirectUrl }: UpdateTagsProps) {
  const { accessToTagLibraryMaterials } = useAuthority();
  const navigate = useNavigate();

  const [updateMaterialTags, { loading: updateMaterialTagsLoading }] = useUpdateLibraryFileTagsMutation({
    refetchQueries: [LibraryDocument]
  });

  const [tags, setTags] = useState([
    ...materialTags.map((t) => ({
      id: t.id,
      name: t.name,
      colorHex: t.colorHex,
      attached: t.attached
    }))
  ]);

  const handleSave = useCallback(async () => {
    if (!tags) return;

    try {
      const attachedTags = tags.filter((t) => t.attached);
      await updateMaterialTags({
        variables: {
          input: {
            materialId: materialId,
            tagIds: attachedTags.map((t) => t.id)
          }
        }
      });
      navigate(redirectUrl ?? '/library', { relative: 'route' });
    } catch (err) {
      console.error(err);
    }
  }, [updateMaterialTags, tags, materialId, navigate, redirectUrl]);

  if (!accessToTagLibraryMaterials) {
    return (
      <PageError
        errorTitle={'В доступе отказано'}
        errorDescription={'У вас нет доступа к изменению тегов для данного материала'}
        reloadAvailable={false}
      />
    );
  }

  const handleTagUpdate = (attached: boolean, tagId: number) => {
    const tagToUpdateIndex = tags.findIndex((t) => t.id === tagId);
    if (tagToUpdateIndex !== undefined) {
      let tagToUpdate = tags[tagToUpdateIndex];
      tagToUpdate.attached = attached;
      let updatedTags = [...tags];
      updatedTags[tagToUpdateIndex] = tagToUpdate;
      setTags([...updatedTags]);
    }
  };

  const lastTagIndex = tags && tags.length > 0 ? tags.length - 1 : 0;
  return (
    <PageSheet className={s.UpdateMaterialTags__sheet}>
      <div className={s.UpdateMaterialTags}>
        <div>
          {tags?.map((tag, index) => {
            return (
              <div key={index}>
                <MaterialTagComp tag={tag} handleTagUpdate={handleTagUpdate} />
                {!(index === lastTagIndex) && <Divider className={s.MaterialTag_divider} />}
              </div>
            );
          })}
        </div>
        <div className={s.UpdateMaterialTags__buttons}>
          <Button className={s.UpdateMaterialTags__saveButton} variant={ButtonVariant.Primary} onClick={handleSave}>
            {updateMaterialTagsLoading ? <Loader size={LoaderSize.xSmall} /> : 'Сохранить'}
          </Button>
          <Button
            className={s.UpdateMaterialTags__cancelButton}
            variant={ButtonVariant.Secondary}
            disabled={updateMaterialTagsLoading}
            onClick={() => {
              navigate(redirectUrl ?? '/library', { relative: 'route' });
            }}>
            Отмена
          </Button>
        </div>
      </div>
    </PageSheet>
  );
}
