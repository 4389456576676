import React from 'react';
import clsx from 'clsx';
import s from './KeyValuePair.module.scss';

export interface KeyValuePairProps {
  valueBold?: boolean;
  keyLabel: string;
  pointColor?: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export function KeyValuePair({ valueBold, keyLabel, pointColor, children, className, style }: KeyValuePairProps) {
  return (
    <div className={clsx(s.KeyValuePair, className)} style={style}>
      <div className={s.KeyValuePair__key}>
        {pointColor && <span style={{ backgroundColor: pointColor }} className={s.KeyValuePair__keyPoint} />}
        <span>{keyLabel}</span>
      </div>

      <div className={clsx(s.KeyValuePair__value, { [s.KeyValuePair__value_bold]: valueBold })}>{children}</div>
    </div>
  );
}
