import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'components/Divider';
import { ErrorMessageProps } from 'components/ErrorMessage/ErrorMessage';
import { ArrowRight, Icon, IconSize } from 'components/Icon';
import { Placeholder, PlaceholderVariant } from 'components/Placeholder';
import { TagDot } from 'components/TagDot';
import { TagWithCountType } from 'store/graphql';
import s from './LibraryTagsList.module.scss';

export interface LibraryTagsListProps {
  tags: TagWithCountType[];
  placeholder?: ErrorMessageProps['description'];
  showLinksList?: boolean;
}

export const LibraryTagsList = ({ tags, placeholder }: LibraryTagsListProps) => {
  return (
    <div className={s.LibraryTagsList}>
      {!tags.length ? (
        <Placeholder className={s.LibraryTagsList__error} variant={PlaceholderVariant.DocumentsSecondary}>
          {placeholder || 'Список пуст'}
        </Placeholder>
      ) : (
        tags.map(({ id, name, count, colorHex }, index) => (
          <Fragment key={id}>
            {index > 0 && <Divider />}
            <Link className={s.TagLink} to={`tags/${id}`}>
              <TagDot color={colorHex} />
              <div className={s.TagLink__name}>
                {name || 'Другие'} ({count})
              </div>
              <Icon className={s.TagLink__arrow} icon={ArrowRight} size={IconSize.Small} />
            </Link>
          </Fragment>
        ))
      )}
    </div>
  );
};
