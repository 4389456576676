import React, { ElementType, MouseEventHandler, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { WrapperComponentProps } from '@digital-gov/ui-utils';
import { Icon } from 'components/Icon';
import s from './ProfileCard.module.scss';

export interface ProfileCardProps<ComponentType extends ElementType = 'button'> extends PropsWithChildren {
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<ComponentType>;
}

export const ProfileCard = <ComponentType extends ElementType = 'button'>({
  className,
  Component = 'button',
  disabled,
  children,
  onClick,
  ...componentProps
}: WrapperComponentProps<ComponentType, ProfileCardProps>) => {
  const handleClick: MouseEventHandler<ComponentType> = (e) => {
    if (disabled) return;
    onClick?.(e);
  };

  const clickable = !!(onClick || componentProps?.to || componentProps?.href);

  return (
    <Component
      className={clsx(s.ProfileCard, className, {
        [s.ProfileCard_clickable]: clickable,
        [s.ProfileCard_disabled]: disabled
      })}
      onClick={handleClick}
      {...componentProps}>
      {children}
    </Component>
  );
};

export interface LinkCardContentProps extends PropsWithChildren {
  icon?: ElementType;
}

export const LinkCardContent = ({ icon, children }: LinkCardContentProps) => {
  return (
    <div className={s.LinkCardContent}>
      {icon && <Icon className={s.LinkCardContent__icon} icon={icon} />}
      {children}
    </div>
  );
};
