import React from 'react';
import { Icon, Search as SearchIcon } from 'components/Icon';
import s from './Search.module.scss';

export interface SearchProps {
  query: string;
  setQuery: (value: string) => void;
}

export function Search({ query, setQuery }: SearchProps) {
  return (
    <div className={s.Search}>
      <input
        type={'search'}
        className={s.Search__input}
        placeholder={'Поиск по названию'}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />

      <Icon icon={SearchIcon} className={s.Search__icon} />
    </div>
  );
}
