import React from 'react';
import { PageError } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { useIndicatorsPageQuery } from 'store/graphql';
import { IndicatorListCard } from './IndicatorListCard';
import { StatsCard } from './StatsCard';
import s from './IndicatorsPage.module.scss';

export function IndicatorsPage() {
  const { data, loading, error } = useIndicatorsPageQuery();
  const keyIndicators = data?.dashboard.keyIndicators;

  return (
    <React.Fragment>
      <PageTitle backTo={'/'}>Ключевые показатели</PageTitle>

      {loading ? (
        <Loader />
      ) : !keyIndicators || !!error ? (
        <PageError />
      ) : (
        <div className={s.IndicatorsPage}>
          <StatsCard keyIndicators={keyIndicators} />
          {!!data && <IndicatorListCard indicators={data.dashboard.keyIndicators.indicators} />}
        </div>
      )}
    </React.Fragment>
  );
}
