import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Overlay } from 'components/Overlay';
import { useAuthority } from 'store/authority';
import { CloseIcon } from './CloseIcon';
import { Search } from './Search';
import s from './DepartmentList.module.scss';

export function DepartmentList({ onClose }: { onClose: () => void }) {
  const { availableDepartments: departments, setAuthority } = useAuthority();
  const [query, setQuery] = useState('');
  const activeDepartmentRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    activeDepartmentRef.current?.scrollIntoView({ block: 'center' });
  }, []);

  return (
    <Overlay onClick={onClose}>
      <aside className={s.DepartmentList__container}>
        <div className={s.DepartmentList__header}>
          <div className={s.DepartmentList__headerContent}>
            <h4 className={s.DepartmentList__heading}>Выбрать ФОИВ</h4>
            <CloseIcon onClick={onClose} />
          </div>

          <Search query={query} setQuery={setQuery} />
        </div>

        <div className={s.DepartmentList}>
          {departments
            .filter(({ department }) => department.toLowerCase().includes(query.toLowerCase()))
            .map(({ authority, department, active }) => (
              <button
                key={department}
                ref={active ? activeDepartmentRef : undefined}
                className={clsx(s.DepartmentList__department, active && s.DepartmentList__department_active)}
                onClick={() => {
                  setAuthority(authority);
                  onClose();
                }}>
                {department}
              </button>
            ))}
        </div>
      </aside>
    </Overlay>
  );
}
