/**
 * Добавляет неразрывный пробел перед последним словом,
 * если оно короче 4 символов
 * @param name
 */
export function formatName(name) {
    if (!name)
        return '';
    let newName = name;
    let nameArray = name.trim().split(' ');
    if (nameArray[nameArray.length - 1].length <= 3) {
        newName = `${nameArray.slice(0, -1).join(' ')}\u00A0${nameArray[nameArray.length - 1]}`;
    }
    return newName;
}
