import React, { ElementType, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { WrapperComponentProps } from '@digital-gov/ui-utils';
import { Icon, IconSize } from 'components/Icon';
import s from './Button.module.scss';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Link = 'link'
}

export interface ButtonProps<ComponentType extends ElementType = 'button'> {
  onClick?: MouseEventHandler<ComponentType>;
  variant?: ButtonVariant;
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
  classes?: {
    icon?: string;
  };
}

export const Button = <ComponentType extends ElementType = 'button'>({
  className,
  children,
  Component = 'button',
  disabled,
  variant = ButtonVariant.Primary,
  iconRight,
  iconLeft,
  onClick,
  classes,
  ...componentProps
}: WrapperComponentProps<ComponentType, ButtonProps<ComponentType>>) => {
  return (
    <Component
      className={clsx(s.Button, s[`Button_${variant}`], { [s.Button_disabled]: disabled }, className)}
      onClick={onClick}
      {...(componentProps?.href && { target: '_blank', rel: 'noopener noreferrer' })}
      {...componentProps}>
      {iconLeft && <Icon className={classes?.icon} icon={iconLeft} size={IconSize.Small} />}
      <span>{children}</span>
      {iconRight && <Icon className={classes?.icon} icon={iconRight} size={IconSize.Small} />}
    </Component>
  );
};
