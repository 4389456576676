import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
import s from './Filter.module.scss';

export type FilterRadioValue<V = string> = V | string;

export type FilterRadioOptionsType<V = string> = {
  value: V;
  label: string;
  disabled?: boolean;
  icon?: React.ElementType;
  iconClassName?: string;
};

export interface FilterRadioProps<V = string> {
  value: V;
  options: FilterRadioOptionsType<V>[];
  onChange: (value: V) => void;
}

export const FilterRadio = <V,>({ value, options, onChange }: FilterRadioProps<V>) => {
  const handleChange = useCallback(
    (checked: boolean, value: V) => {
      if (checked) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <div className={s.FilterRadio}>
      {options.map((item, iItem) => (
        <label
          key={iItem}
          className={clsx(s.FilterRadio__option, {
            [s.FilterRadio__option_checked]: item.value === value,
            [s.FilterRadio__option_disabled]: item.disabled
          })}>
          <input
            className={s.FilterRadio__input}
            type="checkbox"
            checked={item.value === value}
            disabled={item.disabled}
            onChange={(e) => handleChange(e.target.checked, item.value)}
          />
          <div
            className={clsx(s.FilterRadio__optionButton, {
              [s.FilterRadio__optionButton_checked]: item.value === value,
              [s.FilterRadio__optionButton_disabled]: item.disabled
            })}>
            {item.icon && <Icon icon={item.icon} className={clsx(s.FilterRadio__icon, item.iconClassName)} />}
            <div className={s.FilterRadio__label}>{item.label}</div>
          </div>
        </label>
      ))}
    </div>
  );
};
