import React, { useCallback, useState } from 'react';
import { Dialog, DialogPropsType } from 'components/Dialog';

export type UseDialogPropsType<P> = DialogPropsType<P> & {};

export function useDialog<P>(dialogProps: UseDialogPropsType<P>) {
  const [props, setProps] = useState<DialogPropsType<P> | null>(null);

  const handleOpen = useCallback(
    (dProps?: UseDialogPropsType<P>, extProps?: P) => {
      setProps({ ...dialogProps, ...dProps, extProps });
    },
    [dialogProps]
  );

  const handleClose = () => {
    setProps(null);
  };

  const dialog = props && <Dialog onClose={handleClose} {...props} />;

  return [dialog, handleOpen] as const;
}
