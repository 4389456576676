import { FileTypeEnum } from 'store/graphql';
import { ReactComponent as FileBmp } from './assets/file-bmp.svg';
import { ReactComponent as FileDoc } from './assets/file-doc.svg';
import { ReactComponent as FileDocx } from './assets/file-docx.svg';
import { ReactComponent as FileGif } from './assets/file-gif.svg';
import { ReactComponent as FileJpeg } from './assets/file-jpeg.svg';
import { ReactComponent as FileJpg } from './assets/file-jpg.svg';
import { ReactComponent as FileOther } from './assets/file-other.svg';
import { ReactComponent as FilePdf } from './assets/file-pdf.svg';
import { ReactComponent as FilePng } from './assets/file-png.svg';
import { ReactComponent as FilePpt } from './assets/file-ppt.svg';
import { ReactComponent as FilePptx } from './assets/file-pptx.svg';
import { ReactComponent as FileRar } from './assets/file-rar.svg';
import { ReactComponent as FileTxt } from './assets/file-txt.svg';
import { ReactComponent as FileWebp } from './assets/file-webp.svg';
import { ReactComponent as FileXls } from './assets/file-xls.svg';
import { ReactComponent as FileXlsx } from './assets/file-xlsx.svg';
import { ReactComponent as FileZip } from './assets/file-zip.svg';

export const FileIcons = {
  [FileTypeEnum.Bmp]: FileBmp,
  [FileTypeEnum.Doc]: FileDoc,
  [FileTypeEnum.Docx]: FileDocx,
  [FileTypeEnum.Gif]: FileGif,
  [FileTypeEnum.Jpeg]: FileJpeg,
  [FileTypeEnum.Jpg]: FileJpg,
  [FileTypeEnum.Other]: FileOther,
  [FileTypeEnum.Pdf]: FilePdf,
  [FileTypeEnum.Png]: FilePng,
  [FileTypeEnum.Ppt]: FilePpt,
  [FileTypeEnum.Pptx]: FilePptx,
  [FileTypeEnum.Rar]: FileRar,
  [FileTypeEnum.Txt]: FileTxt,
  [FileTypeEnum.WebP]: FileWebp,
  [FileTypeEnum.Xls]: FileXls,
  [FileTypeEnum.Xlsx]: FileXlsx,
  [FileTypeEnum.Zip]: FileZip
};
