import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { PageError } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { ToggleTabs } from 'components/ToggleTabs';
import { useDocumentPageQuery, useViewDocumentMutation } from 'store/graphql';
import { DocumentUtils } from 'utils/DocumentUtils';
import { DocumentHistoryCard, DocumentHistoryCardProps } from '../_components/DocumentHistoryCard';
import { DocumentView, DocumentViewProps } from '../_components/DocumentView';
import { getBackTo } from './getBackTo';
import s from './DocumentPage.module.scss';

enum TabEnum {
  Document = 'document',
  History = 'history'
}

interface DocumentPageCompProps {
  document: DocumentViewProps & { history?: DocumentHistoryCardProps[] | null };
}

const DocumentPageComp = ({ document }: DocumentPageCompProps) => {
  const [activeTab, setActiveTab] = useState(TabEnum.Document);

  const tabs = useMemo(() => {
    return [
      { label: 'Документ', value: TabEnum.Document },
      { label: 'История', value: TabEnum.History, disabled: !document.history?.length }
    ];
  }, [document]);

  return (
    <div className={s.DocumentPage}>
      <ToggleTabs className={s.DocumentPage__toggleTabs} options={tabs} value={activeTab} onChange={setActiveTab} />

      {activeTab === TabEnum.Document && <DocumentView {...document} />}

      {activeTab === TabEnum.History && <>{document.history?.map((h, i) => <DocumentHistoryCard key={i} {...h} />)}</>}
    </div>
  );
};

export function DocumentPage() {
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const location = useLocation();
  const backTo = getBackTo(location, source);

  const { documentId } = useParams<{ documentId: string }>();
  const { loading, error, data } = useDocumentPageQuery({
    variables: { ids: [documentId as string], archive: source === 'archive' }
  });

  const [viewDocument] = useViewDocumentMutation({
    ignoreResults: true,
    variables: { documentId: documentId as string }
  });

  useEffect(() => {
    // отмечает документ как просмотренный
    if (source === 'notifications') viewDocument().catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;
  if (!data || !!error) return <PageError />;

  const document = data.dashboard.documents.items.find((document) => document.documentId === documentId);
  if (!document) return <Navigate to={'/documents'} replace />;

  const documentType = DocumentUtils.getDocumentType(document.documentType);

  return (
    <React.Fragment>
      <PageTitle backTo={backTo}>{documentType}</PageTitle>
      <DocumentPageComp document={document} />
    </React.Fragment>
  );
}
