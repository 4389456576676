import { LabelType } from 'components/Label';
import { CommonDepartmentRequestStatusEnum } from 'store/graphql';

export class RequestUtils {
  static getRequestStatusLabel(
    statusCode: CommonDepartmentRequestStatusEnum,
    statusDisplayContent: string
  ): [string, LabelType] {
    switch (statusCode) {
      case CommonDepartmentRequestStatusEnum.Approved:
        return [statusDisplayContent, LabelType.Success];
      default:
        return [statusDisplayContent, LabelType.Neutral];
    }
  }
}
