import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATE_SHORT_YEAR } from '@digital-gov/ui-utils';
import { Divider } from 'components/Divider';
import { Star, StarFilled, Tag } from 'components/Icon';
import { File, FileMenuAction } from 'features/File';
import { useAuthority } from 'store/authority';
import { LibraryDocument, useSetLibraryFileFavoriteMutation } from 'store/graphql';
import { LibraryFSTreeFileType } from '../../LibraryUtils';
import s from './LibraryFile.module.scss';

export interface LibraryFileProps extends LibraryFSTreeFileType {
  loading?: boolean;
}

export const LibraryFile = ({ fileProps, loading: propsLoading }: LibraryFileProps) => {
  const { id, fullName, fileName, fileType, updatedAt, size, favorite, tags } = fileProps;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accessToTagLibraryMaterials } = useAuthority();

  const [setFileFavoriteMutation, { loading: setFavoriteLoading }] = useSetLibraryFileFavoriteMutation({
    refetchQueries: [LibraryDocument]
  });

  const setFavorite = useCallback(async () => {
    try {
      await setFileFavoriteMutation({
        variables: {
          input: {
            materialId: id,
            favorite: !favorite
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [favorite, id, setFileFavoriteMutation]);

  const favoriteLoading = propsLoading || setFavoriteLoading;

  const sourcePathEncoded = window.btoa(pathname);

  return (
    <>
      <File
        fileId={id}
        fullName={fullName}
        fileName={fileName}
        fileType={fileType}
        size={size}
        updatedAt={updatedAt}
        dateFormatString={DATE_SHORT_YEAR}
        fileInfoDivided={true}
        fileInfoReverse={true}
        tags={tags}
        menuItems={
          <div>
            <FileMenuAction
              icon={favorite ? StarFilled : Star}
              loading={favoriteLoading}
              disabled={favoriteLoading}
              onClick={setFavorite}>
              {favorite ? 'Убрать из избранного' : 'Добавить в избранное'}
            </FileMenuAction>
            {accessToTagLibraryMaterials && (
              <>
                <Divider className={s.LibraryFile_menuDivider} />
                <FileMenuAction
                  icon={Tag}
                  onClick={() =>
                    navigate(`/library/updateTags/${id}?source=${sourcePathEncoded}`, { relative: 'route' })
                  }>
                  {'Добавить теги'}
                </FileMenuAction>
              </>
            )}
          </div>
        }
      />
    </>
  );
};
