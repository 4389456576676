import React from 'react';
import clsx from 'clsx';
import s from './Overlay.module.scss';

export interface OverlayClasses {
  root?: string;
  backdrop?: string;
}

export interface OverlayProps {
  className?: string;
  classes?: OverlayClasses;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
}

export function Overlay({ className, classes, onClick, children }: OverlayProps) {
  return (
    <div className={clsx(s.Overlay, className, classes?.root, { [s.Overlay_interactive]: !!onClick })}>
      <div className={clsx(s.Overlay__backdrop, classes?.backdrop)} onClick={onClick} />
      {children}
    </div>
  );
}
