import { useCallback } from 'react';
import { openExternalLinkRN, rnMessenger } from 'store/rnMessenger';

export function useExternalLink() {
  return useCallback(async (url: string) => {
    try {
      if (rnMessenger.isActive) {
        await openExternalLinkRN(url);
      } else {
        window.location.href = url;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
}
