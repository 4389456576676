import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage } from 'components/ErrorMessage';
import { PageTitle } from 'components/PageTitle';
import { Services, SERVICES } from './services/services';
import s from './ServicesPage.module.scss';

export interface ServicesPageProps {
  allowedServices: Services[];
}

export const ServicesPage = ({ allowedServices }: ServicesPageProps) => {
  const servicesList = [Services.Indicators].filter((s) => allowedServices.includes(s));

  return (
    <>
      <PageTitle title={'Сервисы'} backTo={'/'}>
        Сервисы
      </PageTitle>

      {!servicesList.length ? (
        <ErrorMessage description={'Доступ к сервисам ограничен'} />
      ) : (
        <div className={s.ServicesList}>
          {servicesList.map((item, iItem) => {
            const service = SERVICES[item];
            return (
              <Link key={iItem} className={s.ServicesItem} to={service.path}>
                {service.title}
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};
