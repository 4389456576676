import clsx from 'clsx';
import { Dismiss, Icon, IconSize } from 'components/Icon';
import s from './Tab.module.scss';

export interface TabProps<V> {
  className?: string;
  id: V;
  label: string | number;
  disabled?: boolean;
  isActive?: boolean;
  dotColor?: string;
  onChange: (value: V) => void;
  onDismiss?: (value: V) => void;
}

export function Tab<V = string>({
  className,
  id,
  isActive,
  disabled,
  label,
  dotColor,
  onChange,
  onDismiss
}: TabProps<V>) {
  return (
    <button
      className={clsx(s.Tab, isActive && s.Tab_active, isActive && onDismiss && s.Tab_withIcon, className)}
      disabled={disabled}
      onClick={(e) => {
        // для случая, когда они находятся внутри кнопки / ссылки
        e.preventDefault();
        !disabled && onChange(id);
      }}>
      {dotColor && <div className={s.Tab__dot} style={{ backgroundColor: dotColor }} />}
      {label}
      {isActive && onDismiss && (
        <Icon
          icon={Dismiss}
          size={IconSize.Small}
          onClick={(e) => {
            e.stopPropagation();
            !disabled && onDismiss(id);
          }}
        />
      )}
    </button>
  );
}
