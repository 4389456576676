import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { DocumentCardContent, DocumentCardContentRequisites } from 'features/DocumentCardContent';
import { DocumentType } from 'store/graphql';
import { DocumentUtils } from 'utils/DocumentUtils';
import { ExportInfo } from '../_components/ExportInfo';
import { Label } from '../_components/Label';
import s from './DocumentCard.module.scss';

export interface DocumentCardData extends Pick<DocumentType, 'documentName' | 'status' | 'statusText' | 'statusDate'> {
  showOnlyRequisites?: boolean;
  requisitesTitle?: string;
  requisites: DocumentCardContentRequisites[];
}

export function DocumentCard({ document }: { document: DocumentCardData }) {
  const { documentName, status, statusText, statusDate, showOnlyRequisites, requisitesTitle, requisites } = document;
  const [label, labelType] = DocumentUtils.getDocumentStatusLabel(status);

  return (
    <React.Fragment>
      {!showOnlyRequisites && (
        <React.Fragment>
          <div className={s.DocumentCard__header}>
            <Label type={labelType}>{statusText ?? label}</Label>
            <div className={s.DocumentCard__date}>{formatDate(statusDate, DATE_SHORT_YEAR)}</div>
          </div>

          <p className={s.DocumentCard__description}>{documentName}</p>
        </React.Fragment>
      )}

      <ExportInfo className={s.DocumentCard__exportInfo} />

      {requisitesTitle && <p className={s.DocumentCard__requisitesTitle}>{requisitesTitle}</p>}
      <DocumentCardContent requisites={requisites} />
    </React.Fragment>
  );
}
