import { Location } from 'react-router-dom';
import { URL_KEY_DOCUMENTS_ARCHIVE } from '../urlKeys';

export const getBackTo = (location: Location, source?: string | null) => {
  if (location.key !== 'default') {
    return 'prevPage';
  }

  if (source === 'archive') {
    return `/documents?${URL_KEY_DOCUMENTS_ARCHIVE}=true`;
  }

  if (source === 'notifications') {
    return '/';
  }

  return '/documents';
};
