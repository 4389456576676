import React from 'react';
import clsx from 'clsx';
import { Icon, Search as SearchIcon } from 'components/Icon';
import s from './PageSearch.module.scss';

export interface PageSearchProps {
  query: string;
  setQuery: (query: string) => void;
  count?: number;
  className?: string;
}

export function PageSearch({ query, setQuery, count, className }: PageSearchProps) {
  return (
    <div className={clsx(s.PageSearch, className)}>
      <div className={s.PageSearch__inputContainer}>
        <input
          type={'search'}
          className={s.PageSearch__input}
          placeholder={'Поиск'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        <Icon icon={SearchIcon} className={s.PageSearch__icon} />
      </div>

      {query && typeof count === 'number' && (
        <p className={s.PageSearch__result}>{count ? `Найдено: ${count}` : 'Результатов не найдено'}</p>
      )}
    </div>
  );
}
