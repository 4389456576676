import React, { ReactNode, useMemo } from 'react';
import { WidgetSettingsSheet } from 'features/Widgets';
import { WidgetSettingsProvider } from 'features/Widgets/context/WidgetSettingsProvider';
import { HomePageQuery } from 'store/graphql';
import { BudgetCard } from '../BudgetCard';
import { ExpertReviewCard } from '../ExpertReviewCard';
import { IndicatorsCard } from '../IndicatorsCard';
import s from './WidgetCardList.module.scss';

type Props = {
  data?: HomePageQuery;
};

export enum WidgetEnum {
  Budget = 1,
  Indicators = 2,
  ExpertReview = 3
}

export function WidgetCardsList({ data }: Props) {
  const widgetSettings = data?.dashboard.widgetSettings;

  const list = useMemo(() => {
    if (!widgetSettings) return [];
    return [...widgetSettings].filter((el) => el.visible).sort((a, b) => a.order - b.order);
  }, [widgetSettings]);

  if (!data) return null;

  const { financialAnalysis, keyIndicators, documents } = data.dashboard;

  const Widgets: Record<WidgetEnum, ReactNode> = {
    [WidgetEnum.Budget]: financialAnalysis ? <BudgetCard budget={financialAnalysis} key="budget" /> : null,
    [WidgetEnum.Indicators]: keyIndicators ? <IndicatorsCard keyIndicators={keyIndicators} key="indicators" /> : null,
    [WidgetEnum.ExpertReview]: documents ? <ExpertReviewCard documents={documents.items} key="expertReview" /> : null
  };

  const renderList = () => {
    if (!widgetSettings) return null;
    return list.map((item) => {
      return Widgets[item.id as WidgetEnum];
    });
  };

  return (
    <WidgetSettingsProvider>
      <div className={s.WidgetCardList}>{renderList()}</div>
      <WidgetSettingsSheet />
    </WidgetSettingsProvider>
  );
}
