import React from 'react';
import clsx from 'clsx';
import { Button, ButtonVariant } from 'components/Button';
import { Share } from 'components/Icon';
import { Loader, LoaderSize } from 'components/Loader';
import s from './ShareButton.module.scss';

export function ShareButton({
  share,
  isLoading,
  className
}: {
  share?: () => void;
  isLoading?: boolean;
  className?: string;
}) {
  return (
    <div className={clsx(s.ShareButton, className)}>
      <Button
        iconLeft={Share}
        variant={ButtonVariant.Link}
        disabled={!share}
        onClick={(e) => {
          // может находиться внутри другой кнопки
          e.preventDefault();
          share?.();
        }}>
        Поделиться
      </Button>

      {isLoading && <Loader size={LoaderSize.xSmall} />}
    </div>
  );
}
