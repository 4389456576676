import React, { MouseEventHandler, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Icon } from 'components/Icon';
import { Loader, LoaderSize } from 'components/Loader';
import s from './FileMenuAction.module.scss';

interface FileMenuActionProps extends PropsWithChildren {
  className?: string;
  icon: React.ElementType;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler;
}

export const FileMenuAction = ({ className, icon, disabled, loading, onClick, children }: FileMenuActionProps) => {
  return (
    <button className={clsx(s.FileMenuAction, className)} disabled={disabled} onClick={onClick}>
      {loading ? <Loader className={s.FileMenuAction__loader} size={LoaderSize.xSmall} /> : <Icon icon={icon} />}
      <span>{children}</span>
    </button>
  );
};
