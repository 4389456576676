import { useMemo } from 'react';
import { FormatIntlOptionsType, formatNumberIntl } from '@digital-gov/ui-utils';

const DEFAULT_OPTIONS: FormatIntlOptionsType = { locale: 'ru' };

export const useIndicatorValue = (val: number, options?: FormatIntlOptionsType) => {
  return useMemo(() => {
    const opts: FormatIntlOptionsType = options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS;
    return formatNumberIntl(val, opts);
  }, [val, options]);
};
