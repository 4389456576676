import React from 'react';
import { Link } from 'react-router-dom';
// import { WidgetSettings } from './WidgetSettings';
import s from './WidgetCard.module.scss';

export interface WidgetCardProps {
  to: string;
  children: React.ReactNode;
}

export function WidgetCard({ to, children }: WidgetCardProps) {
  return (
    <Link className={s.WidgetCard} to={to}>
      {children}
      {/* <WidgetSettings /> */}
    </Link>
  );
}
