import React from 'react';
import clsx from 'clsx';
import { Loader } from './Loader';
import s from './LoaderCard.module.scss';

export function LoaderCard({ className }: { className?: string }) {
  return (
    <div className={clsx(s.LoaderCard, className)}>
      <Loader />
    </div>
  );
}
