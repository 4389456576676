import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { saveAuthTarget, useAuth } from '@digital-gov/auth-apollo-store';
import { appBuild } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'components/Button';
import { basename } from 'store/basename';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as WarningIcon } from './warning.svg';
import s from './LoginPage.module.scss';

export function LoginPage() {
  const { logIn, error } = useAuth();
  const [searchParams] = useSearchParams();
  const target = searchParams.get('target');

  useEffect(() => {
    saveAuthTarget(target);
  }, [target]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Вход</title>
      </Helmet>

      <div className={s.LoginPage}>
        <div className={s.LoginPage__content}>
          <Logo className={s.LoginPage__logo} />
          <Button onClick={() => logIn()}>Вход</Button>

          {error && (
            <div className={s.LoginPage__error}>
              <WarningIcon className={s.LoginPage__errorIcon} />
              <span className={s.LoginPage__errorText}>{error}</span>
            </div>
          )}

          <div className={s.LoginPage__links}>
            <Button variant={ButtonVariant.Link} Component={Link} to={'/privacy'}>
              Политика конфиденциальности
            </Button>

            <Button
              variant={ButtonVariant.Link}
              Component={'a'}
              href={encodeURI(`${basename}/uploads/Вход в ЛК РЦТ Инструкция.pdf`)}
              download>
              Скачать PDF-инструкцию
            </Button>
          </div>
        </div>

        <div className={s.LoginPage__appBuild}>{`Сборка ${appBuild}`}</div>
      </div>
    </React.Fragment>
  );
}
