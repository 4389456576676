import React from 'react';
import { WidgetSettingsInput, WidgetSettingsType } from 'store/graphql';

export type WidgetContextType = {
  visible: boolean;
  loading: boolean;
  setVisible: (val: boolean) => void;
  settingsList: WidgetSettingsType[];
  hideItem: (item: WidgetSettingsType) => void;
  showItem: (item: WidgetSettingsType) => void;
  setList: (list: WidgetSettingsType[]) => void;
  resetList: () => void;
  serialize: () => WidgetSettingsInput[];
};

export const WidgetSettingsContext = React.createContext<WidgetContextType>({
  loading: false,
  visible: false,
  setVisible: () => false,
  settingsList: [],
  setList: () => false,
  resetList: () => false,
  hideItem: () => false,
  showItem: () => false,
  serialize: () => []
});
