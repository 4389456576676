import { EMDASH, joinNonEmpty } from '@digital-gov/ui-utils';
import { KeyIndicatorCuratorType, KeyIndicatorType } from 'store/graphql';
import { formatIndicatorValues, indicatorTableYears } from './IndicatorTable/IndicatorTable';

type QueryIndicatorType = Pick<
  KeyIndicatorType,
  | 'departmentId'
  | 'id'
  | 'code'
  | 'name'
  | 'approved'
  | 'approvedFromMc'
  | 'approvedFromOgv'
  | 'color'
  | 'valueFor2022'
  | 'valueFor2023'
  | 'valueFor2024'
  | 'valueFor2025'
  | 'valueInfo'
  | 'haveNested'
> & {
  nestedIndicators: Pick<
    KeyIndicatorType,
    | 'departmentId'
    | 'id'
    | 'code'
    | 'name'
    | 'color'
    | 'valueFor2022'
    | 'valueFor2023'
    | 'valueFor2024'
    | 'valueFor2025'
    | 'valueInfo'
  >[];
  curators?: KeyIndicatorCuratorType[];
};

const RN = '\r\n';
const RNx2 = RN + RN;

export function convertDataToShare(indicator: QueryIndicatorType): ShareData {
  const values = formatIndicatorValues({
    values: [indicator.valueFor2022, indicator.valueFor2023, indicator.valueFor2024, indicator.valueFor2025],
    unit: indicator.valueInfo
  });

  let text =
    `Показатель:` +
    RNx2 +
    indicator.name +
    RNx2 +
    `Значения показателя:` +
    RNx2 +
    indicatorTableYears
      .map((year, i) => {
        return `${year} - ${values[i]}`;
      })
      .join(RN);

  if (indicator.curators?.length) {
    text +=
      RNx2 +
      'Контакты куратора:' +
      RNx2 +
      indicator.curators
        .map((c) => {
          return joinNonEmpty([c.name || EMDASH, c.email], RN);
        })
        .join(RNx2);
  }

  return {
    title: indicator.name,
    text
  };
}
