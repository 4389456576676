import React from 'react';
import { RatingPageQuery } from 'store/graphql';
import { RatingListItem } from './RatingListItem';
import s from './RatingList.module.scss';

interface RatingListProps {
  data: RatingPageQuery;
}

export function RatingList({ data }: RatingListProps) {
  const { operationalRatingIndicators: indicators } = data.dashboard.operationalRating;
  return (
    <div className={s.RatingList}>
      {indicators.map((item) => (
        <RatingListItem item={item} key={`rs-${item.id}`} />
      ))}
    </div>
  );
}
