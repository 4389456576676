import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'components/Divider';
import { Icon, ArrowRight } from 'components/Icon';
import { Indicator } from 'features/_rating/Indicator';
import { RatingDynamicsMap } from 'features/_rating/RatingDynamicsMap';
import { useIndicatorValue } from 'features/_rating/useIndicatorValue';
import { OperationalRatingIndicatorType } from 'store/graphql';
import s from './RatingListItem.module.scss';

interface RatingListItemProps {
  item: OperationalRatingIndicatorType;
}

export function RatingListItem({ item }: RatingListItemProps) {
  const indicatorValue = useIndicatorValue(item.currentValue || 0);

  return (
    <Link key={item.id} to={`/rating/${item.id}`}>
      <div>
        <div className={s.RatingListItem__row}>
          <div className={s.RatingListItem__name}>{item.name}</div>
          <div className={s.RatingListItem__spacer} />
          <Indicator
            position={'left'}
            content={<div className={s.RatingListItem__indicatorVal}>{indicatorValue}</div>}
            showChangeIcon
            dynamics={RatingDynamicsMap[item.dynamic]}
          />

          <Icon icon={ArrowRight} className={s.RatingListItem__arrowIcon} />
        </div>
      </div>
      <Divider />
    </Link>
  );
}
