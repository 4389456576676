import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { usePrevious, useUpdateEffect } from 'react-use';
import { tryParseJSON } from '../object';
/**
 * Modified @react-use "useLocalStorage" hook
 * https://github.com/streamich/react-use/blob/master/src/useLocalStorage.ts
 * @param key
 * @param initialValue
 * @param options
 */
function useSessionStorageReactUse(key, initialValue, options) {
    if (!key) {
        throw new Error('useSessionStorage key may not be falsy');
    }
    const deserializer = useMemo(() => {
        return options ? (options.raw ? (value) => value : options.deserializer) : JSON.parse;
    }, [options]);
    const initializer = useRef((key) => {
        try {
            const serializer = options ? (options.raw ? String : options.serializer) : JSON.stringify;
            const sessionStorageValue = sessionStorage.getItem(key);
            if (sessionStorageValue !== null) {
                return deserializer(sessionStorageValue);
            }
            else {
                initialValue && sessionStorage.setItem(key, serializer(initialValue));
                return initialValue;
            }
        }
        catch (_a) {
            // If user is in private mode or has storage restriction
            // sessionStorage can throw. JSON.parse and JSON.stringify
            // can throw, too.
            return initialValue;
        }
    });
    const [state, setState] = useState(() => initializer.current(key));
    useLayoutEffect(() => setState(initializer.current(key)), [key]);
    const set = useCallback((valOrFunc) => {
        try {
            const newState = typeof valOrFunc === 'function' ? valOrFunc(state) : valOrFunc;
            if (typeof newState === 'undefined')
                return;
            let value;
            if (options)
                if (options.raw)
                    if (typeof newState === 'string')
                        value = newState;
                    else
                        value = JSON.stringify(newState);
                else if (options.serializer)
                    value = options.serializer(newState);
                else
                    value = JSON.stringify(newState);
            else
                value = JSON.stringify(newState);
            sessionStorage.setItem(key, value);
            setState(deserializer(value));
        }
        catch (_a) {
            // If user is in private mode or has storage restriction
            // sessionStorage can throw. Also JSON.stringify can throw.
        }
    }, [deserializer, key, options, state]);
    const remove = useCallback(() => {
        try {
            sessionStorage.removeItem(key);
            setState(undefined);
        }
        catch (_a) {
            // If user is in private mode or has storage restriction
            // sessionStorage can throw.
        }
    }, [key, setState]);
    return [state, set, remove];
}
export function useSessionStorage(key, initialValue, options) {
    const [sessionData, setSessionData, removeSessionData] = useSessionStorageReactUse(key, initialValue, options);
    const prevSessionData = usePrevious(sessionData);
    const handleSessionChange = useCallback((e) => {
        if (e.key === key) {
            const itemStr = e.newValue;
            const item = (tryParseJSON(itemStr) || itemStr);
            setSessionData(item || undefined);
        }
    }, [key, setSessionData]);
    useUpdateEffect(() => {
        const prevStr = JSON.stringify(prevSessionData);
        const newStr = JSON.stringify(sessionData);
        if (prevStr === newStr) {
            return;
        }
        const event = new StorageEvent('storage', {
            key,
            oldValue: JSON.stringify(prevSessionData),
            newValue: JSON.stringify(sessionData)
        });
        window.dispatchEvent(event);
    }, [key, prevSessionData, sessionData]);
    useEffect(() => {
        window.addEventListener('storage', handleSessionChange);
        return () => {
            window.removeEventListener('storage', handleSessionChange);
        };
    }, [handleSessionChange]);
    return [sessionData, setSessionData, removeSessionData];
}
