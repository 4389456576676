import { useCallback } from 'react';
import { useShare } from '@digital-gov/ui-utils';
import { rnMessenger, shareToMobileRN } from 'store/rnMessenger';

export function useShareTextData() {
  const { share } = useShare();
  return useCallback(
    async ({ title, text, url }: ShareData) => {
      try {
        if (rnMessenger.isActive) {
          return (await shareToMobileRN({ message: text, title, url })) as boolean;
        }
        return await share({ title, text });
      } catch (err) {
        console.log(err);
      }
    },
    [share]
  );
}
