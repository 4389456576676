import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { isIOS } from '@digital-gov/ui-utils';
import { ArrowStraightUp, Icon } from 'components/Icon';
import s from './ScrollTopButton.module.scss';

export interface ScrollTopButtonProps {
  visibleHeight?: number;
  navBarVisible?: boolean;
}

export function ScrollTopButton({ visibleHeight = 2000, navBarVisible = true }: ScrollTopButtonProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isIOS) {
      return;
    }

    const handleScroll = () => {
      setIsVisible(window.scrollY >= visibleHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleHeight]);

  if (isIOS) {
    return null;
  }

  const onClickHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      className={clsx(
        s.ScrollTopButton,
        navBarVisible && s.ScrollTopButton_withNavBar,
        isVisible && s.ScrollTopButton_visible
      )}
      onClick={onClickHandler}>
      <Icon icon={ArrowStraightUp} />
    </button>
  );
}
