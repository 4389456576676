import React from 'react';
import clsx from 'clsx';
import s from './Loader.module.scss';

export enum LoaderSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  xSmall = 'xSmall'
}

export interface LoaderProps {
  className?: string;
  size?: LoaderSize;
}

export function Loader({ className, size = LoaderSize.Large }: LoaderProps) {
  return (
    <div className={clsx(s.Loader, s[`Loader_${size}`], className)}>
      <div className={s.Loader__spinner} />
    </div>
  );
}
