import { ReactNativeMessenger } from '@proscom/rn-webview-messenger-web';
import { appBuild } from '@digital-gov/ui-utils';
import { FileTypeEnum } from './graphql';

export const WEBVIEW_METHOD_OPEN_EXTERNAL_LINK = 'openExternalLink';
export const WEBVIEW_METHOD_SHARE = 'share';

export const WEBVIEW_METHOD_DOWNLOAD_FILE = 'downloadFile';

export const rnMessenger = new ReactNativeMessenger({
  // Обязательно:
  // Ссылка на window
  window,
  // По этому имени будет вызываться функция приема сообщения из натива
  callbackName: 'window.rnMessenger.handleMessage',
  // Версия веб-приложения на случай если понадобится определять
  // доступность тех или иных функций
  webVersion: appBuild

  // Опционально:
  // webInfo: {}, // объект в неизменном виде передается в натив
  // debug: true, // можно передать для отладки
  // log: console.log // можно передать для отладки
});

export interface ShareOptions {
  message?: string;
  title?: string;
  url?: string;
  type?: string;
  filename?: string;
}

export interface DownloadOptions {
  downloadUrl: string;
  fileName: string;
  fileType: FileTypeEnum;
}

export async function downloadFileMobileRN(opts: DownloadOptions) {
  return rnMessenger.call(WEBVIEW_METHOD_DOWNLOAD_FILE, opts);
}

export async function shareToMobileRN(opts: ShareOptions) {
  return rnMessenger.call(WEBVIEW_METHOD_SHARE, opts);
}

export async function openExternalLinkRN(url: string) {
  return rnMessenger.call(WEBVIEW_METHOD_OPEN_EXTERNAL_LINK, url);
}

export async function sharePDFtoMobileRN(filename: string, base64Url: string) {
  return shareToMobileRN({ url: base64Url, filename, type: 'application/pdf' });
}
