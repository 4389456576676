import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { Label } from 'components/Label';
import { ShareButton } from 'components/ShareButton';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { DocumentSnapshotType } from 'store/graphql';
import { DocumentUtils } from 'utils/DocumentUtils';
import { Card } from './Card';
import s from './DocumentHistoryCard.module.scss';

export interface DocumentHistoryCardProps extends DocumentSnapshotType {}

export const DocumentHistoryCard = (props: DocumentHistoryCardProps) => {
  const { date, documentName, status, statusText } = props;
  const [label, labelType] = DocumentUtils.getDocumentStatusLabel(status);

  return (
    <Card>
      <article>
        <div className={s.DocumentHistoryCard__header}>
          <Label type={labelType}>{statusText ?? label}</Label>
          <div className={s.DocumentHistoryCard__date}>{formatDate(date, DATE_SHORT_YEAR)}</div>
        </div>

        <p className={s.DocumentHistoryCard__description}>{documentName}</p>

        <div className={s.DocumentHistoryCard__actions}>
          <SharePDF kind={SharePDFKind.DocumentHistoryCard} data={props}>
            {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
          </SharePDF>
        </div>
      </article>
    </Card>
  );
};
