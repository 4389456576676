import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { TagsList } from 'features/_events';
import { TagType } from 'store/graphql';
import s from './EventCard.module.scss';

export interface EventCardProps {
  className?: string;
  id: number;
  header: string;
  tags: TagType[];
  publishDate: string;
  variant?: 'separate' | 'joint';
  important?: boolean;
}

export function EventCard({
  className,
  id,
  header,
  publishDate,
  important,
  tags,
  variant = 'separate'
}: EventCardProps) {
  return (
    <Link
      to={`/events/${id}`}
      className={clsx(s.EventCard, important && s.EventCard_important, s[`EventCard_${variant}`], className)}>
      {important && (
        <>
          <div className={s.EventCard__importantPattern_1} />
          <div className={s.EventCard__importantPattern_2} />
        </>
      )}
      <div className={s.EventCard__content}>
        <p>{header}</p>
        <footer className={s.EventCard__footer}>
          <TagsList tags={tags} maxVisibleTags={2} />
          <p className={s.EventCard__date}>{formatDate(publishDate, DATE_SHORT_YEAR)}</p>
        </footer>
      </div>
    </Link>
  );
}
