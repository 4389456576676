import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { useAuthority } from 'store/authority';
import s from './ExportInfo.module.scss';

export function ExportInfo({ className }: { className?: string }) {
  const { departmentFullName } = useAuthority();
  return (
    <div className={className}>
      <div className={s.ExportInfo__department}>{departmentFullName}</div>
      <div className={s.ExportInfo__date}>
        <span>Дата экспорта</span>
        <span>{formatDate(new Date(), DATE_SHORT_YEAR)}</span>
      </div>
    </div>
  );
}
