import { TagType } from 'store/graphql';
import s from './TagsList.module.scss';

export interface TagsListProps {
  tags: TagType[];
  maxVisibleTags?: number;
}

export function TagsList({ tags, maxVisibleTags }: TagsListProps) {
  const tagsMax = maxVisibleTags ? tags.slice(0, maxVisibleTags) : tags;

  const hiddenTags = tags.length - tagsMax.length;

  return (
    <>
      {tags.length > 0 && (
        <div className={s.TagsList}>
          {tagsMax.map(({ name, colorHex }, index) => (
            <div key={index} className={s.TagsList__tag}>
              <span className={s.TagsList__tagDot} style={{ backgroundColor: colorHex }} />
              <span className={s.TagsList__tagName}>{name}</span>
            </div>
          ))}
          {hiddenTags > 0 && <p className={s.TagsList__hiddenTags}>+ {hiddenTags}</p>}
        </div>
      )}
    </>
  );
}
