import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { EventContent, TagsList } from 'features/_events';
import { EventType } from 'store/graphql';
import s from './Event.module.scss';

export type EventData = Pick<EventType, 'header' | 'content' | 'tags' | 'publishDate'>;

export function Event({ event }: { event: EventData }) {
  return (
    <React.Fragment>
      <h1 className={s.Event__heading}>{event.header}</h1>
      <div className={s.Event__header}>
        <TagsList tags={event.tags} maxVisibleTags={2} />
        <p className={s.Event__date}>{formatDate(event.publishDate, DATE_SHORT_YEAR)}</p>
      </div>
      <EventContent content={event.content} />
    </React.Fragment>
  );
}
