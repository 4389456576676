import React, { useCallback } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { useWidgetSettings } from '../context/useWidgetSettings';
import { SettingsCard } from './SettingsCard';

export function SettingsDNDList() {
  const { settingsList, setList } = useWidgetSettings();

  const handleDragEnd = useCallback(
    (ev: DragEndEvent) => {
      const active = settingsList.find((el) => el.id === ev.active.id);
      const over = settingsList.find((el) => el.id === ev.over?.id);
      if (!active || !over) return;
      const newList = settingsList.map((el) => {
        if (el.id === active.id) {
          return { ...el, order: over.order };
        }
        if (el.id === over.id) {
          return { ...el, order: active.order };
        }
        return el;
      });
      setList(newList);
    },
    [setList, settingsList]
  );

  return (
    <DndContext onDragEnd={handleDragEnd}>
      {settingsList
        .sort((a, b) => a.order - b.order)
        .map((item, idx) => {
          return <SettingsCard item={item} key={item.id} />;
        })}
    </DndContext>
  );
}
