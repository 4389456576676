import React from 'react';
import { useAuth } from '@digital-gov/auth-apollo-store';
import { PageError } from 'components/ErrorMessage';
import { Help, Exit } from 'components/Icon';
import { LoaderCard } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { useProfilePageQuery } from 'store/graphql';
import { ReactComponent as Avatar } from './avatar.svg';
import { LinkCardContent, ProfileCard } from './ProfileCard/ProfileCard';
import s from './ProfilePage.module.scss';

export function ProfilePage() {
  const { logOut } = useAuth();
  const { loading, error, data } = useProfilePageQuery();
  const profile = data?.dashboard.profile;

  return (
    <React.Fragment>
      <PageTitle backTo={'/'}>Профиль</PageTitle>
      <div className={s.ProfilePage}>
        {loading ? (
          <LoaderCard className={s.ProfilePage__loaderCard} />
        ) : !data || !profile || !!error ? (
          <PageError className={s.ProfilePage__error} />
        ) : (
          <ProfileCard Component={'div'}>
            <h3 className={s.ProfilePage__department}>{profile.department.departmentShortName}</h3>
            <div className={s.ProfilePage__user}>
              <Avatar className={s.ProfilePage__userAvatar} />
              <div className={s.ProfilePage__userMeta}>
                <div className={s.ProfilePage__userFullName}>{profile.fullName}</div>
                <div className={s.ProfilePage__userPosition}>{profile.position}</div>
              </div>
            </div>
          </ProfileCard>
        )}

        <ProfileCard disabled>
          <LinkCardContent icon={Help}>Помощь и поддержка</LinkCardContent>
        </ProfileCard>

        <ProfileCard onClick={() => logOut()}>
          <LinkCardContent icon={Exit}>Выйти</LinkCardContent>
        </ProfileCard>
      </div>
    </React.Fragment>
  );
}
