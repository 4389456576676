import { orderBy } from 'lodash-es';
import { LibraryFSTreeNode } from '../../LibraryUtils';

export function addSortIndexForItems(items: LibraryFSTreeNode[]): (LibraryFSTreeNode & { index?: number })[] {
  // сортируем файлы / папки
  // клонируем
  let shadowItems: (LibraryFSTreeNode & { index?: number })[] = items.slice();

  // нас интересует те, что начинаются с числового префикса
  let itemsWithNumberPrefix: { name: string; prefixStartIndex: number; prefixLength: number; id: string }[] = [];
  for (const item of shadowItems) {
    const testRegex = /^([_\s().'^&%!$@?#+-]+\d)|(\d)/gi;
    const itemName = item.name;
    if (testRegex.test(itemName)) {
      const startIndex = itemName.search(/\d/); // начало числового префикса (цифра от 0 до 9)
      const itemNameFromStartIndex = itemName.slice(startIndex);
      const prefixLength = itemNameFromStartIndex.search(/\D/); // конец числового префикса (любой символу, который не является цифрой)
      itemsWithNumberPrefix.push({ name: itemName, prefixStartIndex: startIndex, prefixLength, id: item.id });
    }
  }
  // определяем максимальную длину числового префикса (все префиксы, что короче будем дозаполнять нулями)
  const maxNumberPrefixLength = Math.max(...itemsWithNumberPrefix.map((item) => item.prefixLength));

  // заполняем 0-ми все префиксы (т.е. если где-то был 10000 и 1, станет 10000 и 00001)
  itemsWithNumberPrefix = itemsWithNumberPrefix.map((item) => {
    const zeroRepeats = '0'.repeat(maxNumberPrefixLength - item.prefixLength);
    const newName = item.name.slice(0, item.prefixStartIndex) + zeroRepeats + item.name.slice(item.prefixStartIndex);
    return {
      ...item,
      name: newName
    };
  });
  // заменяем в shadow названия файлов - теперь они с дополненными 0-ми
  for (const item of shadowItems) {
    const updatedItem = itemsWithNumberPrefix.find((i) => i.id === item.id);
    item.name = updatedItem?.name ?? item.name;
  }
  // сортируем по названию (теперь будет ок) и заполняем индекс
  return orderBy(shadowItems, 'name').map((shadowItem, index) => {
    const sourceItem = items.find((i) => i.id === shadowItem.id)!;
    return {
      ...shadowItem,
      name: sourceItem.name,
      index: index // shadow массив файлов уже отсортирован с расширенными названиями, поэтому его индекс - корректный
    };
  });
}
