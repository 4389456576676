import { useMemo } from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { DocumentCardContentRequisites } from 'features/DocumentCardContent';
import { DocumentAttachmentScopeEnum, DocumentStatusEnum, DocumentType } from 'store/graphql';
import { DocumentCard } from './DocumentCard';
import s from './DocumentView.module.scss';

export interface DocumentViewProps
  extends Omit<DocumentType, '_id' | 'viewed' | 'history' | 'outgoingName' | 'favorite'> {}

export const DocumentView = (document: DocumentViewProps) => {
  const {
    incomingDate,
    incomingNumber,
    deadlineDate,
    evaluationObjects,
    status,
    outgoingDate,
    outgoingNumber,
    attachments
  } = document;

  const done = status === DocumentStatusEnum.Executed || status === DocumentStatusEnum.ExecutedBySending;

  const { incomingRequisites, incomingAttachments } = useMemo(() => {
    const requisites: DocumentCardContentRequisites[] = [
      {
        label: 'Объекты оценки',
        value: !evaluationObjects.length ? '–' : evaluationObjects.map((evaluationObject) => evaluationObject)
      }
    ];

    if (!!incomingNumber) {
      requisites.push({
        label: 'Номер входящего',
        value: incomingNumber
      });
    }

    if (!!incomingDate) {
      requisites.push({
        label: 'Дата входящего',
        value: formatDate(incomingDate, DATE_SHORT_YEAR)
      });
    }

    if (!done && !!deadlineDate) {
      requisites.push({
        label: 'Прогнозируемый срок ответа',
        value: formatDate(deadlineDate, DATE_SHORT_YEAR)
      });
    }

    return {
      incomingRequisites: requisites,
      incomingAttachments: attachments.filter(({ scope }) => scope === DocumentAttachmentScopeEnum.Incoming)
    };
  }, [attachments, deadlineDate, done, evaluationObjects, incomingDate, incomingNumber]);

  const outgoing = useMemo(() => {
    if (!done || (!outgoingNumber && !outgoingDate)) {
      return null;
    }

    const requisites: DocumentCardContentRequisites[] = [
      {
        label: 'Объекты оценки',
        value: !evaluationObjects.length ? '–' : evaluationObjects.map((evaluationObject) => evaluationObject)
      }
    ];

    if (!!outgoingNumber) {
      requisites.push({
        label: 'Номер исходящего',
        value: outgoingNumber
      });
    }

    if (!!outgoingDate) {
      requisites.push({
        label: 'Дата исходящего',
        value: formatDate(outgoingDate, DATE_SHORT_YEAR)
      });
    }

    return {
      outgoingRequisites: requisites,
      outgoingAttachments: attachments.filter(({ scope }) => scope === DocumentAttachmentScopeEnum.Outgoing)
    };
  }, [attachments, done, evaluationObjects, outgoingDate, outgoingNumber]);

  return (
    <div className={s.DocumentView}>
      <DocumentCard {...document} requisites={incomingRequisites} attachments={incomingAttachments} />
      {outgoing && (
        <DocumentCard
          {...document}
          showOnlyRequisites
          requisites={outgoing.outgoingRequisites}
          requisitesTitle={'Результат экспертной оценки'}
          attachments={outgoing.outgoingAttachments}
        />
      )}
    </div>
  );
};
