import { useMemo } from 'react';
import { EventsListQuery } from 'store/graphql';

type EventsType = EventsListQuery['dashboard']['events'];
type EventsEntries = EventsType['entries'];

export const useCreateEventsGroups = (events?: EventsType | null) =>
  useMemo(() => {
    const groups: EventsEntries[] = [];

    if (!events) {
      return groups;
    }

    let group: EventsEntries = [];

    events.entries.forEach((event) => {
      if (event.important) {
        if (group.length) {
          groups.push(group);
          group = [];
        }
        groups.push([event]);
        return;
      }

      group.push(event);
    });

    if (group.length) {
      groups.push(group);
    }

    return groups;
  }, [events]);
