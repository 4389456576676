import React, { MouseEventHandler, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Button, ButtonVariant } from 'components/Button';
import { ErrorMessageProps } from 'components/ErrorMessage/ErrorMessage';
import { Placeholder, PlaceholderVariant } from 'components/Placeholder';
import { LibrarySort } from '../../library.types';
import { LibraryFSTreeNode, LibraryUtils } from '../../LibraryUtils';
import { LibraryFile } from '../LibraryFile/LibraryFile';
import { LibraryFolder } from '../LibraryFolder/LibraryFolder';
import s from './LibraryList.module.scss';

export interface LibraryListProps {
  className?: string;
  nodes?: LibraryFSTreeNode[];
  sort: LibrarySort;
  tagId?: number;
  initialItemsToShow?: number;
  showMorePath?: string;
  placeholder?: ErrorMessageProps['description'];
  loading?: boolean;
  onFolderClick?: MouseEventHandler;
}

export const LibraryList = ({
  className,
  nodes,
  sort,
  initialItemsToShow,
  showMorePath,
  placeholder,
  loading,
  onFolderClick
}: LibraryListProps) => {
  const [showAll, setShowAll] = useState(!initialItemsToShow || (nodes?.length || 0) <= initialItemsToShow);

  const data = useMemo(() => {
    return LibraryUtils.sortNodes(nodes || [], sort);
  }, [nodes, sort]);

  const dataVisible = useMemo(() => {
    if (showAll || !initialItemsToShow) return data;
    const folders = data.folders.slice(0, Math.min(initialItemsToShow, data.folders.length));
    const files = data.files.slice(0, initialItemsToShow - folders.length);
    return {
      folders,
      files
    };
  }, [data, showAll, initialItemsToShow]);

  return (
    <div className={clsx(s.LibraryList, className)}>
      {!nodes?.length ? (
        <Placeholder className={s.LibraryList__error} variant={PlaceholderVariant.DocumentsSecondary}>
          {placeholder || 'Список пуст'}
        </Placeholder>
      ) : (
        <>
          {dataVisible.folders.map((folder, i) => (
            <LibraryFolder key={i} {...folder} onClick={onFolderClick} />
          ))}
          {dataVisible.files.map((file) => (
            <LibraryFile key={file.id} {...file} loading={loading} />
          ))}
        </>
      )}

      {initialItemsToShow && !showAll && (
        <Button
          className={s.LibraryList__button}
          variant={ButtonVariant.Link}
          Component={showMorePath ? Link : 'button'}
          to={showMorePath}
          onClick={!showMorePath ? () => setShowAll(true) : undefined}>
          Смотреть все
        </Button>
      )}
    </div>
  );
};
