import s from './DocumentCardContent.module.scss';

export interface DocumentCardContentRequisites {
  label: string;
  value: string | string[];
}

export interface DocumentCardContentProps {
  requisites: DocumentCardContentRequisites[];
}

export const DocumentCardContent = ({ requisites }: DocumentCardContentProps) => {
  return (
    <div className={s.DocumentCardContent}>
      {requisites.map(({ label, value }, idx) => (
        <div key={idx} className={s.DocumentCardContent__item}>
          <div className={s.DocumentCardContent__label}>{label}</div>
          {(Array.isArray(value) ? value : [value]).map((val, idx) => (
            <p key={idx} className={s.DocumentCardContent__value}>
              {val}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};
