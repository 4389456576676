import { useMemo } from 'react';
import { IndicatorChartPointType } from 'store/graphql';

export const useChartData = (data?: IndicatorChartPointType[]) => {
  // оставляем только уникальные по таймстемпу точки
  return useMemo(() => {
    const chartData: IndicatorChartPointType[] = [];
    const dates = new Set(data?.map((el) => el.date));
    for (const date of Array.from(dates.values())) {
      const chartPoint = data?.find((el) => el.date === date);
      if (chartPoint) chartData.push({ date, value: chartPoint.value });
    }
    return chartData;
  }, [data]);
};
