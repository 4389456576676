import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import s from './Filter.module.scss';

export interface FilterProps extends PropsWithChildren {
  className?: string;
  title?: string;
}

export const Filter = ({ className, title, children }: FilterProps) => {
  return (
    <div className={clsx(s.Filter, className)}>
      {title && <div className={s.Filter__title}>{title}</div>}
      <div className={s.Filter__content}>{children}</div>
    </div>
  );
};
