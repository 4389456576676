import React, { useState } from 'react';
import { formatNumber, EMDASH, useClickOutside } from '@digital-gov/ui-utils';
import { InfoIcon, Tooltip, TooltipArrowPlacement } from 'components/Tooltip';
import { KeyIndicatorColorsEnum } from 'store/graphql';
import s from './IndicatorTable.module.scss';

type Value = string | null | undefined;

export interface IndicatorTableProps {
  unit: string;
  color: KeyIndicatorColorsEnum;
  values: [Value, Value, Value, Value];
  className?: string;
}

export const indicatorTableYears = [2022, 2023, 2024, 2025];

export function formatIndicatorValues({ values, unit }: Pick<IndicatorTableProps, 'values' | 'unit'>) {
  return values.map((value) => {
    if (unit === 'да/нет') {
      return value === '1' ? 'Да' : 'Нет';
    } else if (value) {
      return formatNumber(+value, 0);
    } else {
      return EMDASH;
    }
  });
}

export function IndicatorTable({ unit, color, values, className }: IndicatorTableProps) {
  const [showUnit, setShowUnit] = useState(false);
  const [tooltip, setTooltip] = useState<HTMLDivElement | null>(null);

  useClickOutside(tooltip, () => setShowUnit(false));

  const formattedValues = formatIndicatorValues({ values, unit });

  return (
    <div className={className}>
      <div className={s.IndicatorTable__header}>
        <span>Значения показателя</span>

        <div className={s.IndicatorTable__tooltipContainer}>
          {!!unit && <InfoIcon active={showUnit} onClick={() => setShowUnit(true)} />}

          {showUnit && (
            <Tooltip ref={setTooltip} className={s.IndicatorTable__tooltip} arrowPlacement={TooltipArrowPlacement.Left}>
              {unit}
            </Tooltip>
          )}
        </div>
      </div>

      <div className={s.IndicatorTable}>
        {indicatorTableYears.map((year) => (
          <div key={year} className={s.IndicatorTable__year}>
            {year}
          </div>
        ))}

        {formattedValues.map((value, index) => {
          let textColor;
          if (index === 0) {
            if (color === KeyIndicatorColorsEnum.Red) textColor = '#ff564e';
            if (color === KeyIndicatorColorsEnum.Green) textColor = '#46b755';
          }

          return (
            <div key={`${index}-${value}`} className={s.IndicatorTable__value} style={{ color: textColor }}>
              {value}
            </div>
          );
        })}
      </div>
    </div>
  );
}
