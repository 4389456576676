import React from 'react';
import clsx from 'clsx';
import { Tab, TabProps } from './Tab';
import s from './Tabs.module.scss';

export interface TabsProps<V> {
  className?: string;
  innerScroll?: boolean;
  value: V;
  onChange: (value: V) => void;
  options: Omit<TabProps<V>, 'onChange'>[];
}

export function Tabs<V = string>({ className, innerScroll, value, onChange, options }: TabsProps<V>) {
  return (
    <div className={clsx(s.Tabs, { [s.Tabs_innerScroll]: innerScroll }, className)}>
      {options.map((o) => (
        <Tab className={s.Tabs__tab} key={`${o.id}`} isActive={value === o.id} onChange={onChange} {...o} />
      ))}
    </div>
  );
}
