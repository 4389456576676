import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { NBSP, tryNumberLike } from '@digital-gov/ui-utils';
import { Collapse } from 'components/Collapse';
import { PageError } from 'components/ErrorMessage';
import { Icon, IconSize, DotMenu } from 'components/Icon';
import { Loader } from 'components/Loader';
import { PageSearch } from 'components/PageSearch';
import { PageTitle } from 'components/PageTitle';
import { TagDot } from 'components/TagDot';
import { LibraryMaterialType, TagWithCountType, useLibraryQuery } from 'store/graphql';
import { LibraryList } from './_components/LibraryList/LibraryList';
import { LibraryTagsList } from './_components/LibraryTagsList/LibraryTagsList';
import { SectionCard } from './_components/SectionCard/SectionCard';
import { MaterialTag, UpdateMaterialTags } from './_components/UpdateLibraryFileTags/UpdateMaterialTags';
import { useLibraryFilters } from './LibraryFilters/useLibraryFilters';
import { LibraryFSTreeNode, LibraryUtils } from './LibraryUtils';
import s from './LibraryPage.module.scss';

enum Sections {
  New = 'new',
  Favourite = 'favourite',
  Tags = 'tags',
  All = 'all',
  UpdateTags = 'updateTags'
}

interface LibraryPageCompProps {
  materials: LibraryMaterialType[];
  tags: TagWithCountType[];
  path: string;
  loading: boolean;
}

const defaultSectionsState = {
  [Sections.New]: true,
  [Sections.Favourite]: false,
  [Sections.Tags]: true,
  [Sections.All]: false
};

function LibraryPageComp({ materials, tags, path, loading }: LibraryPageCompProps) {
  const { tagId: paramsTagId, materialId: paramsMaterialId } = useParams<{ tagId: string; materialId: string }>();
  const [searchParams] = useSearchParams();
  const tagId = tryNumberLike(paramsTagId, undefined, true);
  const selectedTag = tags.find((t) => t.id === tagId);

  const materialId = tryNumberLike(paramsMaterialId, undefined, true);
  const selectedMaterial = materials.find((m) => m.id === materialId);
  const selectedMaterialTags: MaterialTag[] = tags.map((tag) => {
    const attached = selectedMaterial?.tags.find((t) => t.id === tag.id) !== undefined;
    return {
      ...tag,
      attached
    };
  });
  const encodedSourceUrl = searchParams.get('source');
  const sourceUrl = encodedSourceUrl ? window.atob(encodedSourceUrl) : undefined;

  const isSectionNew = path.includes(Sections.New);
  const isSectionFavourite = path.includes(Sections.Favourite);
  const isSectionUpdateTags = path.includes(Sections.UpdateTags);

  const [search, setSearch] = useState('');

  const pathArray = useMemo(() => {
    return decodeURI(path).replace('/library', '').split('/').filter(Boolean);
  }, [path]);

  useEffect(() => {
    setSearch('');
  }, [path]);

  const [filterComp, filter, filterOpen, setFilterOpen] = useLibraryFilters({ pathArray });

  const fsTree = useMemo(() => {
    return LibraryUtils.buildFSTree(materials);
  }, [materials]);

  const currDir = useMemo(() => {
    return LibraryUtils.getTreeByPath(pathArray, fsTree.fsTree);
  }, [fsTree, pathArray]);

  const newFiles = useMemo(() => {
    if (!fsTree.fsTree.nodes.length || !fsTree.updatedAt) return [];
    return LibraryUtils.searchNewFiles(fsTree.fsTree, fsTree.updatedAt);
  }, [fsTree]);

  const favoriteFiles = useMemo(() => {
    if (!fsTree.fsTree.nodes.length) return [];
    return LibraryUtils.searchFavoriteFiles(fsTree.fsTree);
  }, [fsTree]);

  const tagFiles = useMemo(() => {
    if (!fsTree.fsTree.nodes.length || !selectedTag) return [];
    return LibraryUtils.searchFilesByTagId(fsTree.fsTree, selectedTag.id);
  }, [fsTree, selectedTag]);

  const foundItems = useMemo(() => {
    if (!fsTree.fsTree.nodes.length || !search) return [];
    return LibraryUtils.search(search, fsTree.fsTree);
  }, [fsTree, search]);

  const backTo = useMemo(() => {
    if (!!search) return;
    if (selectedTag) return '/library';
    if (selectedMaterial) return sourceUrl ?? '/library';
    return pathArray.length ? pathArray.slice(0, -1).join('/') || '/library' : '/';
  }, [pathArray, search, selectedTag, selectedMaterial, sourceUrl]);

  if (!currDir && !selectedTag && !isSectionNew && !isSectionFavourite && !isSectionUpdateTags) {
    return <Navigate to={'/library'} replace />;
  }

  const title = (() => {
    if (search) {
      return 'Поиск по материалам';
    }
    if (isSectionNew) {
      return 'Новое';
    }
    if (isSectionFavourite) {
      return 'Избранное';
    }
    if (isSectionUpdateTags) {
      return 'Добавить теги';
    }
    if (selectedTag) {
      return selectedTag.name || 'Теги';
    }
    return currDir?.name || 'Материалы для РЦТ';
  })();

  const isRootDir = !currDir?.name && !search && !selectedTag && !isSectionNew && !isSectionFavourite;

  return (
    <React.Fragment>
      <PageTitle
        title={'Библиотека'}
        backTo={backTo}
        slotRight={
          !isSectionUpdateTags && (
            <Icon
              className={clsx(s.LibraryPage__filtersButton, filterOpen && s.LibraryPage__filtersButton_active)}
              icon={DotMenu}
              size={IconSize.Medium}
              onClick={() => setFilterOpen(!filterOpen)}
            />
          )
        }>
        <div className={s.LibraryPage__title}>
          {selectedTag && <TagDot color={selectedTag.colorHex} />} {title}
        </div>
      </PageTitle>

      {isSectionUpdateTags && selectedMaterial ? (
        <UpdateMaterialTags
          materialId={selectedMaterial.id}
          materialTags={selectedMaterialTags}
          redirectUrl={sourceUrl}
        />
      ) : (
        <>
          <div
            className={clsx(s.LibraryPage, {
              [s.LibraryPage_nestedDir]: !isRootDir
            })}>
            <div className={s.LibraryPage__header}>
              <PageSearch query={search} setQuery={setSearch} count={foundItems?.length || 0} />
            </div>

            <div className={s.LibraryPage__content}>
              {(() => {
                if (isRootDir) {
                  return (
                    <>
                      <Collapse title={'Новое'} isOpen={defaultSectionsState[Sections.New]}>
                        <SectionCard>
                          <LibraryList
                            nodes={newFiles}
                            initialItemsToShow={3}
                            sort={filter.sort}
                            loading={loading}
                            showMorePath={'/library/new'}
                          />
                        </SectionCard>
                      </Collapse>

                      <Collapse title={'Избранное'} isOpen={defaultSectionsState[Sections.Favourite]}>
                        <SectionCard>
                          <LibraryList
                            nodes={favoriteFiles}
                            initialItemsToShow={3}
                            sort={filter.sort}
                            placeholder={`Вы еще не добавили ни одного документа в${NBSP}избранное`}
                            loading={loading}
                            showMorePath={'/library/favourite'}
                          />
                        </SectionCard>
                      </Collapse>

                      <Collapse title={'Теги'} isOpen={defaultSectionsState[Sections.Tags]}>
                        <SectionCard>
                          <LibraryTagsList tags={tags} placeholder={'Нет ни одного тега'} />
                        </SectionCard>
                      </Collapse>

                      <Collapse title={'Все'} isOpen={defaultSectionsState[Sections.All]}>
                        <SectionCard>
                          <LibraryList nodes={currDir?.nodes} sort={filter.sort} />
                        </SectionCard>
                      </Collapse>
                    </>
                  );
                }

                let nodes: LibraryFSTreeNode[] | undefined;
                if (isSectionNew) {
                  nodes = newFiles;
                } else if (isSectionFavourite) {
                  nodes = favoriteFiles;
                } else {
                  nodes = (selectedTag && tagFiles) || currDir?.nodes;
                }

                return (
                  <LibraryList
                    nodes={(search && foundItems) || nodes}
                    sort={filter.sort}
                    loading={loading}
                    placeholder={'Ничего не найдено'}
                    onFolderClick={() => setSearch('')}
                  />
                );
              })()}
            </div>
          </div>

          {filterOpen && filterComp}
        </>
      )}
    </React.Fragment>
  );
}

export function LibraryPage() {
  const { pathname } = useLocation();
  const { data, loading, error } = useLibraryQuery({
    notifyOnNetworkStatusChange: true
  });

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return <PageError />;
  }

  return (
    <LibraryPageComp
      path={pathname}
      materials={data.dashboard.library.materials}
      tags={data.dashboard.library.tags}
      loading={loading}
    />
  );
}
