import React from 'react';
import clsx from 'clsx';
import { formatNumber } from '@digital-gov/ui-utils';
import { Divider } from 'components/Divider';
import { KeyValuePair } from 'components/KeyValuePair';
import { RatioBar, RatioBarSize } from 'components/RatioBar';
import { KeySystemsType, KeySystemType } from 'store/graphql';
import { ExportInfo } from '../_components/ExportInfo';
import s from './Systems.module.scss';

export type SystemsData = Pick<KeySystemsType, 'keyCount' | 'totalBudget'> & {
  systems: Pick<KeySystemType, 'id' | 'name' | 'budgetTotal' | 'budgetDevelopment' | 'budgetExploitation'>[];
};

export function Systems({ keySystems }: { keySystems: SystemsData }) {
  return (
    <React.Fragment>
      <div className={s.Systems__header}>
        <h2 className={s.Systems__heading}>Ключевые системы</h2>
        <div className={s.Systems__value}>{keySystems.keyCount}</div>
      </div>

      <ExportInfo />

      <div className={clsx(s.Systems__header, s.Systems__header_list)}>
        <div>
          <h3 className={s.Systems__budget}>
            <span>Финансирование,</span>
            <span className={s.Systems__unit}> млн</span>
          </h3>
          <div className={s.Systems__hint}>ВР 242 и 246</div>
        </div>

        <div className={s.Systems__value}>{formatNumber(keySystems.totalBudget, 2, true)}</div>
      </div>

      {keySystems.systems.map(({ id, name, budgetTotal, budgetDevelopment, budgetExploitation }, index) => (
        <React.Fragment key={id}>
          <div className={s.System}>
            <div className={s.System__header}>
              <h2 className={s.System__heading}>{name}</h2>
              <div className={s.System__budget}>{budgetTotal}</div>
            </div>

            <RatioBar
              size={RatioBarSize.Small}
              data={[
                { value: budgetDevelopment, color: '#7cb6fa' },
                { value: budgetExploitation, color: '#0b3fb2' }
              ]}
            />

            <div className={s.System__pairs}>
              <KeyValuePair pointColor={'#7cb6fa'} keyLabel={'Создание и развитие'}>
                {formatNumber(budgetDevelopment, 2, true)}
              </KeyValuePair>
              <KeyValuePair pointColor={'#0b3fb2'} keyLabel={'Эксплуатация'}>
                {formatNumber(budgetExploitation, 2, true)}
              </KeyValuePair>
            </div>
          </div>

          {index !== keySystems.systems.length - 1 && <Divider className={s.System__divider} />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
