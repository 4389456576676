import React from 'react';
import { PageError } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { useIndicatorsAgreementQuery } from 'store/graphql';
import { Services, SERVICES } from '../../services';
import { IndicatorListCard } from './IndicatorListCard';
import s from './IndicatorsPage.module.scss';

const title = SERVICES[Services.Indicators].title;
const backTo = '/services';

export const IndicatorsPage = () => {
  const { data, loading, error } = useIndicatorsAgreementQuery();

  const metadata = data?.dashboard.keyIndicators;

  const total = metadata?.total ?? 0;
  const key = metadata?.key ?? 0;
  const reached = metadata?.achievedOrApproved ?? 0;
  const unreached = metadata?.failedOrDisagreement ?? 0;

  const indicators = data?.dashboard.keyIndicators.indicators;

  if (loading) {
    return <Loader />;
  }

  if (!indicators || !!error) {
    return <PageError />;
  }

  return (
    <>
      <PageTitle title={title} backTo={backTo}>
        {title}
      </PageTitle>

      <div className={s.Indicators}>
        <IndicatorListCard indicators={indicators} counters={{ total, key, reached, unreached }} />
      </div>
    </>
  );
};
