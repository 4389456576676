import React, { useState } from 'react';
import { uniqBy } from 'lodash-es';
import { compareDateStrings } from '@digital-gov/ui-utils';
import { Divider } from 'components/Divider';
import { Tabs } from 'components/Tabs';
import { DepartmentRequestType, DocumentType } from 'store/graphql';
import { DocumentUtils } from 'utils/DocumentUtils';
import { RequestUtils } from 'utils/RequestUtils';
import { Notification } from '../_components/Notification';
import s from './NotificationList.module.scss';

export enum NotificationCategory {
  Unread,
  Documents,
  Requests,
  Other
}

export interface NotificationListProps {
  requests: Omit<DepartmentRequestType, 'departmentId' | 'groundsNames' | 'groundsDescriptions'>[];
  documents: Pick<
    DocumentType,
    'documentId' | 'documentType' | 'documentName' | 'viewed' | 'status' | 'statusText' | 'statusDate'
  >[];
}

export function NotificationList(data: NotificationListProps) {
  const requests = data.requests.map((r) => {
    const [label, labelType] = RequestUtils.getRequestStatusLabel(r.statusCode, r.statusDisplayContent);
    return {
      to: `/request/${r.requestId}`,
      kind: r.requestTypeName,
      date: r.stateUpdatedAt || r.createdDate,
      children: r.requestName,
      unread: !r.viewed,
      label,
      labelType
    };
  });

  const documents = data.documents.map((d) => {
    const [label, labelType] = DocumentUtils.getDocumentStatusLabel(d.status);
    const kind = DocumentUtils.getDocumentType(d.documentType);

    return {
      to: `/documents/${d.documentId}?source=notifications`,
      kind,
      date: d.statusDate || new Date().toISOString(),
      children: d.documentName,
      unread: !d.viewed,
      label: d.statusText ?? label,
      labelType
    };
  });

  const unread = [...requests, ...documents].filter(({ unread }) => unread);
  const [category, setCategory] = useState(unread.length ? NotificationCategory.Unread : NotificationCategory.Requests);

  const notifications = uniqBy(
    // для некоторых authority (например, 15) приходят дубликаты элементов
    category === NotificationCategory.Unread
      ? unread
      : category === NotificationCategory.Requests
        ? requests
        : category === NotificationCategory.Documents
          ? documents
          : [],
    'to'
  );

  notifications.sort((a, b) => compareDateStrings(-1)(a.date, b.date));

  return (
    <div className={s.NotificationList}>
      <div className={s.NotificationList__header}>
        <h2 className={s.NotificationList__heading}>Уведомления</h2>

        <Tabs<NotificationCategory>
          className={s.NotificationList__tabs}
          innerScroll
          value={category}
          onChange={setCategory}
          options={[
            { id: NotificationCategory.Unread, label: `Непрочитанные (${unread.length})`, disabled: !unread.length },
            { id: NotificationCategory.Documents, label: 'Документы', disabled: !documents.length },
            { id: NotificationCategory.Requests, label: 'Изменения', disabled: !requests.length },
            { id: NotificationCategory.Other, label: 'Прочее', disabled: true }
          ]}
        />
      </div>

      <div className={s.NotificationList__notifications}>
        {notifications.map((notification, index) => (
          <React.Fragment key={notification.to}>
            <Notification {...notification}>{notification.children}</Notification>
            {index !== notifications.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
