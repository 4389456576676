import React from 'react';
import { ApolloError } from '@apollo/client';
import { useAuth } from '@digital-gov/auth-apollo-store';
import { Button, ButtonVariant } from 'components/Button';
import { ErrorMessage } from 'components/ErrorMessage';
import s from './AuthorityError.module.scss';

export function AuthorityError(_: { error?: ApolloError }) {
  // критическая ошибка, блокирующая все дальнейшие действия (даже загрузку меню / UserLayout)
  // TODO: попытаться отразить причину ошибки – ошибка сети или ошибка от API Минцифры
  const { logOut } = useAuth();
  return (
    <main className={s.AuthorityError__container}>
      <div className={s.AuthorityError}>
        <ErrorMessage title={'Данные пользователя недоступны'} />
        <div className={s.AuthorityError__actions}>
          <Button className={s.AuthorityError__actionButton} variant={ButtonVariant.Secondary} onClick={() => logOut()}>
            Выйти
          </Button>
          <Button className={s.AuthorityError__actionButton} onClick={() => window.location.reload()}>
            Обновить
          </Button>
        </div>
      </div>
    </main>
  );
}
