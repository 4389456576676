import { FilterRadioOptionsType } from 'components/BottomSheet';
import { Sort } from 'components/Icon';
import { SortDirectionEnum } from 'store/graphql';
import s from './EventsSort.module.scss';

export const sortOptions: FilterRadioOptionsType<SortDirectionEnum>[] = [
  {
    label: 'Сначала новые',
    value: SortDirectionEnum.Desc,
    icon: Sort
  },
  {
    label: 'Сначала старые',
    value: SortDirectionEnum.Asc,
    icon: Sort,
    iconClassName: s.EventsSort__button_asc
  }
];
