import React, { useState } from 'react';
import { Check, Icon, IconSize } from 'components/Icon';
import { TagDot } from 'components/TagDot';
import { MaterialTag } from './UpdateMaterialTags';
import s from './MaterialTag.module.scss';

export interface MaterialTagProps {
  tag: MaterialTag;
  handleTagUpdate: (attached: boolean, tagId: number) => void;
}

export function MaterialTagComp({ tag, handleTagUpdate }: MaterialTagProps) {
  const [checked, setChecked] = useState(tag.attached ?? false);

  const handleChange = () => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    if (tag) {
      handleTagUpdate(newCheckedValue, tag.id);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className={s.MaterialTag}>
        <TagDot color={tag.colorHex} />
        <div>{tag.name}</div>
      </div>
      <label className={s.MaterialTag__switcher}>
        <input className={s.MaterialTag__switcherInput} type="checkbox" checked={checked} onChange={handleChange} />
        <div className={s.MaterialTag__switcherBox}>
          {checked && <Icon className={s.MaterialTag__switcherCheckIcon} icon={Check} size={IconSize.Small} />}
        </div>
      </label>
    </div>
  );
}
