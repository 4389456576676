import { createContext } from 'react';
import { ApolloError } from '@apollo/client';
import { EMDASH } from '@digital-gov/ui-utils';

export interface IAuthorityContext {
  loading?: boolean;
  error?: ApolloError;
  authority: number | null;
  setAuthority: (authority: number) => void;
  department: string;
  departmentFullName: string;
  accessToTagLibraryMaterials: boolean;
  availableDepartments: {
    authority: number;
    department: string;
    departmentFullName: string;
    active: boolean;
  }[];
}

export const AuthorityContext = createContext<IAuthorityContext>({
  authority: null,
  accessToTagLibraryMaterials: false,
  setAuthority: () => {},
  department: EMDASH,
  departmentFullName: EMDASH,
  availableDepartments: []
});
