import React from 'react';
import clsx from 'clsx';
import { formatNumber } from '@digital-gov/ui-utils';
import { PageSheet } from 'components/_layouts/PageSheet';
import { Divider } from 'components/Divider';
import { ErrorMessage, PageError } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { ShareButton } from 'components/ShareButton';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { useSystemsPageQuery } from 'store/graphql';
import { SystemCard } from './_components/SystemCard';
import s from './SystemsPage.module.scss';

export function SystemsPage() {
  const { data, error, loading } = useSystemsPageQuery();
  const keySystems = data?.dashboard.keySystems;

  return (
    <React.Fragment>
      <PageTitle backTo={'/'}>Ключевые системы</PageTitle>

      {loading ? (
        <Loader />
      ) : !keySystems || !!error ? (
        <PageError />
      ) : (
        <PageSheet className={s.SystemsPage}>
          <div className={s.SystemsPage__header}>
            <h2 className={s.SystemsPage__quantity}>Количество</h2>
            <div className={s.SystemsPage__value}>{keySystems.keyCount}</div>
          </div>

          <div className={s.SystemsPage__systems}>
            {loading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <div className={clsx(s.SystemsPage__header, s.SystemsPage__header_systemList)}>
                  <div className={s.SystemsPage__budget}>
                    <h3 className={s.SystemsPage__budgetTitle}>
                      <span>Финансирование,</span>
                      <span className={s.SystemsPage__budgetUnit}> млн</span>
                    </h3>
                    <div className={s.SystemsPage__budgetHint}>ВР 242 и 246</div>
                  </div>

                  <div className={s.SystemsPage__value}>{formatNumber(keySystems.totalBudget, 2, true)}</div>
                </div>

                {keySystems.systems.map((system, index, systems) => (
                  <React.Fragment key={system.id}>
                    <SystemCard {...system} />
                    {index !== systems.length - 1 && <Divider />}
                  </React.Fragment>
                ))}

                <ErrorMessage
                  className={s.SystemsPage__errorMessage}
                  description={
                    <span>
                      Мы работаем над детализацией <br />
                      расходов по проектам
                    </span>
                  }
                />

                <SharePDF kind={SharePDFKind.Systems} data={keySystems}>
                  {({ share, isLoading }) => (
                    <ShareButton className={s.SystemsPage__share} share={share} isLoading={isLoading} />
                  )}
                </SharePDF>
              </React.Fragment>
            )}
          </div>
        </PageSheet>
      )}
    </React.Fragment>
  );
}
