export enum Services {
  Indicators = 'indicators',
  Indicator = 'indicator'
}

export const SERVICES = {
  [Services.Indicators]: {
    path: 'indicators',
    title: 'Согласование показателей'
  },
  [Services.Indicator]: {
    path: ':indicatorId',
    title: 'Согласование показателей'
  }
};

export type ServiceRouteType = {
  id: Services;
  path: string;
  routes?: ServiceRouteType[];
};

function getServiceObject(service: Services, routes?: ServiceRouteType['routes']): ServiceRouteType {
  return {
    id: service,
    ...SERVICES[service],
    routes
  };
}

export const servicesRoutes: ServiceRouteType[] = [
  getServiceObject(Services.Indicators, [getServiceObject(Services.Indicator)])
];
