import React, { ElementType, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { WrapperComponentProps } from '@digital-gov/ui-utils';
import { Icon, IconSize } from 'components/Icon';
import s from './RoundIconButton.module.scss';

export enum RoundIconButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary'
}

export enum RoundIconButtonSize {
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xSmall'
}

export interface RoundIconButtonProps<ComponentType extends ElementType = 'button'> {
  onClick?: MouseEventHandler<ComponentType>;
  variant?: RoundIconButtonVariant;
  size?: RoundIconButtonSize;
  icon: ElementType;
}

export const RoundIconButton = <ComponentType extends ElementType = 'button'>({
  className,
  Component = 'button',
  disabled,
  variant = RoundIconButtonVariant.Primary,
  size = RoundIconButtonSize.Medium,
  icon,
  onClick,
  ...rest
}: WrapperComponentProps<ComponentType, RoundIconButtonProps<ComponentType>>) => {
  return (
    <Component
      className={clsx(
        s.IconButton,
        s[`IconButton_${variant}`],
        s[`IconButton_${size}`],
        { [s.IconButton_disabled]: disabled },
        className
      )}
      onClick={onClick}
      {...(rest?.href && { target: '_blank', rel: 'noopener noreferrer' })}
      {...rest}>
      <Icon icon={icon} size={size === RoundIconButtonSize.Medium ? IconSize.Medium : IconSize.Small} />
    </Component>
  );
};
