import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Button, ButtonVariant } from 'components/Button';
import { ArrowLeft } from 'components/Icon';
import { RoundIconButton } from 'components/RoundIconButton';
import { ScrollTopButton } from 'features/ScrollTopButton';
import { useExternalLink } from 'hooks/useExternalLink';
import s from './PrivacyPage.module.scss';

export function PrivacyPage() {
  const handleMailTo = useExternalLink();
  return (
    <article className={s.PrivacyPage}>
      <Helmet>
        <title>Политика конфиденциальности</title>
      </Helmet>

      <header className={s.PrivacyPage__header}>
        <RoundIconButton icon={ArrowLeft} Component={Link} to={'/'} />
        <h1>Политика конфиденциальности</h1>
      </header>

      <section>
        <h2>1. Основные понятия, используемые в Политике конфиденциальности персональных данных</h2>
        <p>
          <strong>Администрация Приложения</strong> — уполномоченные сотрудники ФГБУ «ЦЭКИ» на управление Приложением,
          действующие от имени ФГБУ «ЦЭКИ», которые организуют и (или) осуществляют обработку персональных данных, а
          также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке,
          действия (операции), совершаемые с персональными данными.
        </p>
        <p>
          <strong>Приложение</strong> — сайт https://baza.eskigov.ru (далее — Приложение) и иные интернет-сервисы,
          которые ссылаются на настоящую Политику конфиденциальности персональных данных, и через которые ООО «Простые
          коммуникации» собирает персональные данные любых лиц, посещающих указанный сайт.
        </p>
        <p>
          <strong>Пользователь Приложения</strong> — лицо, имеющее доступ к Приложению посредством сети Интернет и
          использующее Приложение для своих целей (субъект персональных данных).
        </p>
        <p>
          <strong>Персональные данные</strong> — любая информация, относящаяся к прямо или косвенно определённому или
          определяемому физическому лицу — Пользователю Приложения (субъекту персональных данных).
        </p>
        <p>
          <strong>Конфиденциальность персональных данных</strong> — обязательное для соблюдения Администрацией
          Приложения требование не раскрывать третьим лицам и не распространять персональные данные без согласия
          субъекта персональных данных, если иное не предусмотрено федеральным законом.
        </p>
        <p>
          <strong>Обработка персональных данных</strong> — любое действие (операция) или совокупность действий
          (операций), совершаемых с использованием средств автоматизации или без использования таких средств с
          персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
          изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных.
        </p>
        <p>
          <strong>Обработка персональных данных с использованием средств автоматизации</strong> — обработка персональных
          данных с помощью средств вычислительной техники.
        </p>
        <p>
          <strong>Распространение персональных данных</strong> — действия, направленные на раскрытие персональных данных
          неопределённому кругу лиц.
        </p>
        <p>
          <strong>Предоставление персональных данных</strong> — действия, направленные на раскрытие персональных данных
          определённому лицу или определённому кругу лиц.
        </p>
        <p>
          <strong>Блокирование персональных данных</strong> — временное прекращение обработки персональных данных (за
          исключением случаев, если обработка необходима для уточнения персональных данных).
        </p>
        <p>
          <strong>Уничтожение персональных данных</strong> — действия, в результате которых становится невозможным
          восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате
          которых уничтожаются материальные носители персональных данных.
        </p>
        <p>
          <strong>IP-адрес</strong> — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
        </p>
        <p>
          <strong>Cookie</strong> — это небольшой текстовый файл, который веб-сервер размещает на жёстком диске
          компьютера Пользователя. Сookie-файлы могут быть как «сеансовые», так и «постоянные».
        </p>
      </section>

      <section>
        <h2>2. Общие положения</h2>
        <p>
          2.1. Настоящая Политика конфиденциальности персональных данных Общества с ограниченной ответственностью
          "Простые коммуникации" (далее – Политика конфиденциальности) разработана ООО "Простые коммуникации" (далее –
          Администрация Приложения) и применяется при использовании Приложения, иных интернет-сервисов, которые
          ссылаются на настоящую Политику конфиденциальности, и через которые ООО "Простые коммуникации" собирает
          персональные данные любых лиц, посещающих использующих указанное Приложение.
        </p>
        <p>
          2.2. Политика конфиденциальности разработана в соответствии с требованиями Федерального закона от 27.07.2006 №
          152-ФЗ «О персональных данных» (далее – Федеральный закон «О персональных данных»), иных федеральных законов,
          регулирующих вопросы обработки персональных данных, а также принятых во их исполнение подзаконных нормативных
          правовых актов.
        </p>
        <p>
          2.3. Использование сервисов Приложения Пользователем Приложения означает его безоговорочное согласие с
          Политикой конфиденциальности и условиями обработки персональных данных Пользователя Приложения.
        </p>
        <p>
          2.4. В случае несогласия с условиями Политики конфиденциальности Пользователь Приложения должен прекратить
          использование Приложения.
        </p>
        <p>
          2.5. Политика конфиденциальности определяет основные цели и условия обработки персональных данных
          Пользователей Приложения и сведения о реализуемых требованиях к защите персональных данных Пользователей
          Приложения.
        </p>
        <p>
          2.6. Политика конфиденциальности применяется только к Приложению. Администрация Приложения не контролирует и
          не несет ответственность за сайты третьих лиц, на которые Пользователь Приложения может перейти по ссылкам,
          доступным в Приложении.
        </p>
        <p>
          2.7. Администрация Приложения является оператором только тех персональных данных, которые получает от
          Пользователей Приложения при использовании Приложения с их согласия, предоставляемого путем совершения
          Пользователем Приложения конклюдентных действий в Приложении.
        </p>
        <p>
          2.8. Администрация Приложения не проверяет достоверность персональных данных, предоставляемых Пользователем
          Приложения в Приложении. Администрация Приложения исходит из того, что Пользователь Приложения предоставляет
          достоверные и достаточные персональные данные и поддерживает эту информацию в актуальном состоянии. Всю
          ответственность за последствия предоставления недостоверных или недействительных персональных данных несет
          лично Пользователь Приложения.
        </p>
        <p>
          2.9. Пользователь Приложения подтверждает, что, предоставляя свои персональные данные и давая согласие на их
          обработку, он действует добровольно, своей волей и в своем интересе, а также подтверждает свою дееспособность.
        </p>
        <p>
          2.10. Предоставляя свои персональные данные, Пользователь Приложения соглашается с тем, что предоставленные им
          персональные данные будут обрабатываться ООО "Простые коммуникации" (адрес: 115054, г. Москва, ул.
          Дубининская, д. 57, стр. 2, этаж 1, пом. II, ком.7) как с использованием средств автоматизации, так и без
          использования таких средств.
        </p>
        <p>
          2.11. Персональные данные, разрешённые к обработке в рамках Политики конфиденциальности, не являются
          специальными или биометрическими, предоставляются Пользователем Приложения путём заполнения специальных форм в
          Приложении бессрочно (вплоть до отзыва Пользователем Приложения своего согласия на обработку персональных
          данных). В зависимости от заполняемой Пользователем веб-формы, данные включают в себя следующую информацию:
        </p>
        <ul>
          <li>фамилию, имя, отчество;</li>
          <li>контактный телефон;</li>
          <li>адрес электронной почты (e-mail);</li>
          <li>адрес аккаунта в социальной сети;</li>
          <li>название организации (места работы);</li>
          <li>должность.</li>
        </ul>
        <p>
          Заполняя соответствующие веб-формы и/или отправляя свои персональные данные Администрации Приложения,
          Пользователь Приложения выражает свое согласие с Политикой конфиденциальности.
        </p>
        <p>
          2.12. Администрация Приложения также принимает меры по защите персональных данных, которые автоматически
          передаются в процессе посещения страниц Приложения, в том числе из файлов cookie:
        </p>
        <ul>
          <li>IP адрес, присвоенный компьютеру Пользователя в момент посещения Приложения;</li>
          <li>Полученные данные о сессиях.</li>
        </ul>
        <p>Сookie-файлы могут быть как «сеансовые», так и «постоянные».</p>
        <p>
          Администрация Приложения использует сеансовые cookie-файлы, чтобы присвоить уникальный идентификационный номер
          компьютеру Пользователя Приложения при каждом посещении Приложения, при этом после закрытия браузера они
          удаляются. Такие файлы применяются также для анализа работы Пользователя Приложения с Приложением (изучается
          посещение страниц, используемых ссылок и время пребывания Пользователя на той или иной странице).
        </p>
        <p>
          Приложение распознает постоянные cookie, которые хранятся на жестких дисках компьютеров Пользователей
          Приложения, и, присваивая устройствам Пользователей Приложения уникальные идентификаторы, Администрация
          Приложения может создать базу данных о действиях и предпочтениях Пользователей Приложения (в частности, о
          частоте посещения и периодичности возвращения Пользователей Приложения, об их предпочтениях в Приложении).
          Важно, что cookie-файлы не содержат личных данных Пользователей Приложения, они только фиксируют их действия.
        </p>
        <p>
          Сеансовые cookie-файлы не требуют предварительного согласия Пользователей Приложения; постоянные cookie-файлы
          такого согласия требуют.
        </p>
        <p>
          Управлять cookie-файлами Пользователи Приложения могут через настройки веб-браузера. Таким образом, у них есть
          возможность разрешить использование всех cookie-файлов, интегрированных в страницы или заблокировать
          cookie-файлы, а также включить использование cookie-файлов по запросу, принимать или отклонять cookie-файлы.
          Управление cookie-файлами различается в зависимости от используемого браузера.
        </p>
        <p>
          При этом применяя технологию cookie, Администрация Приложения не хранит и не использует никакие конкретные
          данные о Пользователях Приложения. Администрация Приложения обращает внимание, что Пользователь Приложения
          вправе настроить браузер на отказ от регистрации обращений к Приложению или на предупреждения о запросах на
          подобную регистрацию. Отключение «cookie» может повлечь невозможность доступа к Приложению.
        </p>
        <p>
          2.13. Если Администрация Приложения не сможет никаким образом соотнести информацию, указанную в пунктах
          2.11-2.12 Политики конфиденциальности с Пользователем Приложения (физическим лицом), Администрация Приложения
          не будет считать данную информацию персональными данными.
        </p>
      </section>

      <section>
        <h2>3. Цели обработки персональных данных Пользователей Приложения</h2>
        <p>
          3.1. Приложение собирает и хранит только те персональные данные Пользователя Приложения, которые необходимы
          для направления Пользователем запросов и информации, предоставления сервисов или исполнения соглашений и
          договоров с Пользователем Приложения, за исключением случаев, когда законодательством предусмотрено
          обязательное хранение персональных данных в течение определенного законом срока.
        </p>
        <p>3.2. Персональные данные Пользователя Приложение обрабатывает в следующих целях:</p>
        <p>
          3.2.1. Предоставления Пользователям Приложения регистрации (авторизации) в Приложении, доступа к Приложению и
          любых других случаях, связанных с такими действиями.
        </p>
        <p>
          3.2.2. Предоставления Пользователю Приложения возможности для направления запросов и информации, касающихся
          использования Приложения.
        </p>
        <p>
          3.2.3. Установления с Пользователем Приложения обратной связи, включая направление уведомлений, запросов,
          касающихся использования Приложения, оказания услуг, обработку запросов и заявок от Пользователя Приложения.
        </p>
        <p>3.2.4. Создания учетной записи, если Пользователь Приложения дал согласие на создание учетной записи.</p>
        <p>
          3.2.5. Предоставления Пользователю Приложения эффективной клиентской и технической поддержки при возникновении
          проблем, связанных с использованием Приложения.
        </p>
        <p>
          3.2.6. Обеспечения работоспособности и безопасности Приложения, для подтверждения совершаемых Пользователями
          Приложения действий, для предотвращения случаев мошенничества, компьютерных атак и иных злоупотреблений, а
          также для расследования таких случаев.
        </p>
      </section>

      <section>
        <h2>4. Условия обработки персональных данных Пользователей Приложения и передача их третьим лицам</h2>
        <p>
          4.1. Приложение хранит персональные данные Пользователей Приложения на собственных серверах, а также на
          серверах облачных сервисов управления базами данных.
        </p>
        <p>
          4.2. В отношении персональных данных Пользователя Приложения сохраняется их конфиденциальность, кроме случаев
          добровольного предоставления Пользователем Приложения данных о себе для общего доступа неограниченному кругу
          лиц. При использовании отдельных сервисов Пользователь Приложения соглашается с тем, что определенная часть
          его персональных данных становится общедоступной.
        </p>
        <p>
          4.3. Приложение вправе передать персональные данные Пользователя Приложения третьим лицам в следующих случаях:
        </p>
        <p>4.3.1. Пользователь Приложения выразил согласие на такие действия.</p>
        <p>
          4.3.2. Передача необходима для использования Пользователем Приложения определенного сервиса либо для
          исполнения определенного соглашения или договора с Пользователем Приложения.
        </p>
        <p>
          4.3.3. Передача предусмотрена законодательством Российской Федерации в рамках установленной законодательством
          процедуры.
        </p>
        <p>
          4.4. Настоящим Администрация Приложения уведомляет Пользователя Приложения, что в том случае, если
          Пользователь Приложения пожелал внести уточнения в его персональные данные, заблокировать или уничтожить их в
          случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не
          являются необходимыми для заявленной цели обработки, либо в случае возникновения у Пользователя желания
          отозвать свое согласие на обработку персональных данных или устранить неправомерные действия Администрации
          Приложения в отношении персональных данных Пользователя Приложения, то Пользователь Приложения должен
          направить Администрации Приложения официальный запрос по адресу: 115054, г. Москва, ул. Дубининская, д. 57,
          стр. 2, этаж 1, пом. II, ком.7, ООО "Простые коммуникации".
        </p>
        <p>
          4.5. Администрация Приложения обязуется немедленно прекратить обработку персональных данных Пользователя
          Приложения с момента получения от Пользователя Приложения письменного заявления (отзыва) и в случае, если
          сохранение персональных данных более не требуется для целей обработки персональных данных, уничтожить их в
          срок и на условиях, установленных законодательством Российской Федерации.
        </p>
        <p>
          4.6. В случае достижения цели обработки персональных данных Администрация Приложения обязуется прекратить
          обработку персональных данных и уничтожить их в срок и на условиях, установленных законодательством Российской
          Федерации.
        </p>
        <p>
          4.7. В случае отзыва Пользователем Приложения согласия на обработку персональных данных Администрация
          Приложения вправе продолжить обработку персональных данных без согласия Пользователя Приложения при наличии
          оснований, указанных в п. 2-11 ч. 1 ст. 6, ч. 2 ст. 10 и ч. 2 ст. 11 Федерального закона «О персональных
          данных».
        </p>
      </section>

      <section>
        <h2>5. Права и обязательства сторон</h2>
        <p>5.1. Пользователь Приложения имеет право:</p>
        <p>
          5.1.1. Предоставлять корректную информацию о персональных данных, необходимую для пользования Приложением.
        </p>
        <p>
          5.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной
          информации.
        </p>
        <p>5.2. Администрация Приложения обязуется:</p>
        <p>
          5.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 3 настоящей Политики
          конфиденциальности.
        </p>
        <p>
          5.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного
          согласия Пользователя Приложения, а также не осуществлять продажу, обмен, опубликование либо разглашение иными
          возможными способами переданных персональных данных Пользователя Приложения, за исключением предусмотренных
          настоящей Политикой конфиденциальности.
        </p>
        <p>
          5.2.3. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю Приложения, с
          момента обращения или запроса Пользователя Приложения или его законного представителя либо уполномоченного
          органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных
          персональных данных или неправомерных действий.
        </p>
      </section>

      <section>
        <h2>6. Ответственность сторон</h2>
        <p>
          6.1. Администрация Приложения несёт ответственность за умышленное разглашение персональных данных Пользователя
          Приложения в соответствии с действующим законодательством Российской Федерации, за исключением случаев,
          предусмотренных настоящей Политикой конфиденциальности.
        </p>
        <p>
          6.2. В случае утраты или разглашения персональных данных Администрация Приложения не несёт ответственность,
          если данная конфиденциальная информация:
        </p>
        <p>6.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
        <p>6.2.2. Была получена от третьей стороны до момента её получения Администрацией Приложения.</p>
        <p>6.2.3. Была разглашена с согласия Пользователя Приложения.</p>
        <p>6.2.4. Была получена третьими лицами путем несанкционированного доступа к файлам Приложения.</p>
      </section>

      <section>
        <h2>7. Сведения о реализуемых требованиях к защите персональных данных</h2>
        <p>
          7.1. Администрация Приложения принимает необходимые и достаточные организационные и технические меры для
          защиты персональных данных Пользователя Приложения от неправомерного или случайного доступа, уничтожения,
          изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
        </p>
      </section>

      <section>
        <h2>8. Дополнительные условия</h2>
        <p>
          8.1. Администрация Приложения вправе вносить изменения в настоящую Политику конфиденциальности без согласия
          Пользователя.
        </p>
        <p>
          8.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения в Приложении, если иное не
          предусмотрено новой редакцией Политики конфиденциальности.
        </p>
        <p>
          8.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать на адрес
          электронной почты:{' '}
          <Button
            className={s.PrivacyPage__mailButton}
            variant={ButtonVariant.Link}
            onClick={() => handleMailTo('mailto:support@gis.gov.ru')}>
            support@gis.gov.ru
          </Button>
          .
        </p>
      </section>

      <ScrollTopButton navBarVisible={false} />
    </article>
  );
}
