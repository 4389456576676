import React, { useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@digital-gov/ui-utils';
import { KeyValuePair } from 'components/KeyValuePair';
import { RatioBar } from 'components/RatioBar';
import { InfoIcon, Tooltip } from 'components/Tooltip';
import { FinancialAnalysisType } from 'store/graphql';
import { formatBudget, formatPercent } from 'utils/formatBudget';
import s from './BudgetCardContent.module.scss';

export type BudgetCardContentData = Pick<
  FinancialAnalysisType,
  'budget' | 'acceptedBudgetCommitments' | 'cashExecution' | 'remainder'
>;

export interface BudgetCardContentProps {
  className?: string;
  budget: BudgetCardContentData;
}

export function BudgetCardContent({ className, budget }: BudgetCardContentProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [tooltip, setTooltip] = useState<HTMLDivElement | null>(null);

  useClickOutside(tooltip, () => setShowDetails(false));

  return (
    <div className={clsx(s.BudgetCardContent, className)}>
      <div className={s.BudgetCardContent__header}>
        <h3 className={s.BudgetCardContent__heading}>
          <span>Бюджет,</span>
          <span className={s.BudgetCardContent__unit}> млн</span>
        </h3>

        <div className={s.BudgetCardContent__budget}>{formatBudget(budget.budget)}</div>
      </div>

      <div className={s.BudgetCardContent__cashExecutionContainer}>
        <div className={s.BudgetCardContent__cashExecution}>{`Касса, ${formatBudget(budget.cashExecution)}`}</div>

        <InfoIcon
          active={showDetails}
          onClick={(e) => {
            // может использоваться внутри ссылки
            e.preventDefault();
            setShowDetails(true);
          }}
        />
      </div>

      {showDetails && (
        <Tooltip
          ref={setTooltip}
          className={s.BudgetCardContent__tooltip}
          arrowClassName={s.BudgetCardContent__tooltipArrow}>
          <p>{`Свободный остаток - ${formatPercent(budget.remainder, budget.budget)}`}</p>
          <p>{`Принятые БО - ${formatPercent(budget.acceptedBudgetCommitments, budget.budget)}`}</p>
          <p>{`Кассовое исполнение - ${formatPercent(budget.cashExecution, budget.budget)}`}</p>
        </Tooltip>
      )}

      <RatioBar
        className={s.BudgetCardContent__ratioBar}
        markPercent={(budget.cashExecution / budget.budget) * 100}
        data={[
          { value: budget.acceptedBudgetCommitments, color: '#16a086' },
          { value: budget.remainder, color: '#f8b200' }
        ]}
      />

      <div className={s.BudgetCardContent__pairs}>
        <KeyValuePair valueBold pointColor={'#f8b200'} keyLabel={'Свободный остаток'}>
          {formatBudget(budget.remainder)}
        </KeyValuePair>
        <KeyValuePair valueBold pointColor={'#16a086'} keyLabel={'Принятые БО'}>
          {formatBudget(budget.acceptedBudgetCommitments)}
        </KeyValuePair>
      </div>
    </div>
  );
}
