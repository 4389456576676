import { useQueryParams } from '@digital-gov/ui-utils';
import { SortDirectionEnum } from 'store/graphql';
import { URL_KEY_EVENTS_SORT } from '../urlKeys';

export const useQuerySort = () =>
  useQueryParams(URL_KEY_EVENTS_SORT, SortDirectionEnum.Desc, {
    getter: (value): SortDirectionEnum => {
      if (value && Object.values(SortDirectionEnum).includes(value as SortDirectionEnum)) {
        return value as SortDirectionEnum;
      }

      return SortDirectionEnum.Desc;
    },
    setter: (value: SortDirectionEnum) => {
      return value;
    }
  });
