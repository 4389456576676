import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { DATE_DAY_MONTH_SHORT, formatDate, formatNumberIntl } from '@digital-gov/ui-utils';
import { OperationalRatingIndicatorType } from 'store/graphql';
import { useChartData } from './useChartData';

type RatingChartBlockProps = Pick<OperationalRatingIndicatorType, 'maxValue' | 'chart'>;

const ChartColors: Record<string, string> = {
  axisGrid: '#bdc6cd',
  axisLine: '#798c9d',
  tickText: '#66727f',
  chartLine: '#1da284',
  chartDot: '#0b3fb2'
};

export function RatingChartBlock({ maxValue, chart }: RatingChartBlockProps) {
  const data = useChartData(chart);
  if (!data.length) return null;

  const renderTick = ({ x, y, payload }: any) => {
    const isMaxVal = payload.value === maxValue;
    return (
      <g transform={`translate(${x},${y})`}>
        <text fill={ChartColors.tickText} style={{ fontWeight: 400, fontSize: 10 }} dy={16} dx={-14}>
          {formatNumberIntl(Math.round(payload.value * 100) / 100, { locale: 'ru' })} {isMaxVal ? 'макс' : null}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer height={130}>
      <LineChart data={data} margin={{ top: 6 }}>
        <CartesianGrid strokeDasharray={'5 5'} style={{ stroke: ChartColors.axisGrid }} vertical={false} />

        <XAxis
          dataKey={'date'}
          padding={{ left: 80, right: 50 }}
          axisLine={{ stroke: ChartColors.axisLine }}
          tickFormatter={(val) => formatDate(val, DATE_DAY_MONTH_SHORT).slice(0, 6)}
          tickLine={false}
          tick={{ fontSize: 12, fontWeight: 400, fill: ChartColors.tickText }}
          tickMargin={8}
        />

        <YAxis
          domain={[0, maxValue || 'auto']}
          type={'number'}
          axisLine={false}
          style={{ fontWeight: 400, fontSize: 12 }}
          tick={renderTick}
          tickLine={false}
          interval={0}
          tickCount={4}
          width={1}
          tickMargin={-20}
        />

        <Line
          legendType="none"
          isAnimationActive={false}
          dataKey="value"
          stroke={ChartColors.chartLine}
          dot={({ cx, cy, payload }) => <circle key={payload.date} r={6} fill={ChartColors.chartDot} cx={cx} cy={cy} />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
