var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
export function useShare() {
    const handleCanShare = useCallback((data) => {
        try {
            return navigator.canShare(data);
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }, []);
    const handleShare = useCallback((data) => __awaiter(this, void 0, void 0, function* () {
        if (!handleCanShare(data))
            return false;
        try {
            yield navigator.share(data);
            return true;
        }
        catch (err) {
            if (!String(err).includes('AbortError')) {
                console.error(err);
            }
            return false;
        }
    }), [handleCanShare]);
    return {
        share: handleShare,
        canShare: handleCanShare
    };
}
