import React, { Fragment, MouseEventHandler, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DATE_SHORT_YEAR, formatDate, formatName } from '@digital-gov/ui-utils';
import { Icon } from 'components/Icon';
import { ReactComponent as Folder } from '../../assets/folder.svg';
import { LibraryFSTreeFolderType, LibraryUtils } from '../../LibraryUtils';
import s from './LibraryFolder.module.scss';

export interface LibraryFolderProps extends LibraryFSTreeFolderType {
  onClick?: MouseEventHandler;
}

export const LibraryFolder = (props: LibraryFolderProps) => {
  const { id, name, path, updatedAt, onClick } = props;

  const pathStr = useMemo(() => {
    const pathIdIndex = path.findIndex((p) => p === id);
    return (pathIdIndex < 0 ? [] : path.slice(0, pathIdIndex + 1)).join('/');
  }, [id, path]);

  const innerItems = useMemo(() => {
    return LibraryUtils.countInnerNodes(props);
  }, [props]);

  const innerItemsAmount = innerItems.folders + innerItems.files;

  const infoArray = [formatDate(updatedAt, DATE_SHORT_YEAR), `Объектов: ${innerItemsAmount}`].filter(Boolean);

  return (
    <Link className={s.LibraryFolder} to={`/library/${pathStr}`} onClick={onClick}>
      <div className={s.LibraryFolder__iconBox}>
        <Icon className={s.LibraryFolder__icon} icon={Folder} />
      </div>

      <div className={s.LibraryFolder__content}>
        <p className={s.LibraryFolder__name}>{formatName(name)}</p>

        <div className={s.LibraryFolder__info}>
          {infoArray.map((item, iItem) => (
            <Fragment key={iItem}>
              {iItem > 0 && <span className={s.LibraryFolder__infoDivider} />}
              <span>{item}</span>
            </Fragment>
          ))}
        </div>
      </div>
    </Link>
  );
};
