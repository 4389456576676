import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useQueryParams } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'components/Button';
import { PageError } from 'components/ErrorMessage';
import { PageSearch } from 'components/PageSearch';
import { PageTitle } from 'components/PageTitle';
import { DocumentTypeEnum, useDocumentListQuery } from 'store/graphql';
import { DocumentList } from './_components/DocumentList';
import { useQueryTypes } from './hooks/useQueryTypes';
import { URL_KEY_DOCUMENTS_ARCHIVE, URL_KEY_DOCUMENTS_SEARCH } from './urlKeys';
import s from './DocumentsPage.module.scss';

const SEARCH_DELAY = 500;
const DOCUMENTS_LIMIT = 20;

export function DocumentsPage() {
  const [urlSearch, setUrlSearch] = useQueryParams(URL_KEY_DOCUMENTS_SEARCH);
  const [archive, setArchive] = useQueryParams(URL_KEY_DOCUMENTS_ARCHIVE);
  const location = useLocation();
  const [documentTypes, setDocumentTypes] = useQueryTypes();

  const isArchive = !!archive;
  const [search, setSearch] = useState(urlSearch ?? '');

  useEffect(() => {
    setSearch(urlSearch ?? '');
  }, [urlSearch]);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const { data, networkStatus, fetchMore, error } = useDocumentListQuery({
    variables: {
      archive: isArchive,
      search: urlSearch,
      offset: 0,
      limit: DOCUMENTS_LIMIT,
      documentTypes
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });

  const onFetchMore = useCallback(() => {
    if (!data?.dashboard.documents.items) {
      return;
    }

    fetchMore({
      variables: {
        offset: data.dashboard.documents.items.length
      }
    });
  }, [data?.dashboard.documents.items, fetchMore]);

  const documentTypesChangeHandler = (val: DocumentTypeEnum | null) => {
    setDocumentTypes(val);
  };

  const archiveBackToSearchParams = new URLSearchParams(location.search.substring(1));
  archiveBackToSearchParams.delete(URL_KEY_DOCUMENTS_ARCHIVE);

  return (
    <React.Fragment>
      {isArchive ? (
        <PageTitle backTo={`/documents?${archiveBackToSearchParams.toString()}`}>Архив</PageTitle>
      ) : (
        <PageTitle
          backTo={'/'}
          slotRight={
            <Button variant={ButtonVariant.Link} onClick={() => setArchive('true')}>
              Архив
            </Button>
          }>
          Документы
        </PageTitle>
      )}

      {error ? (
        <PageError />
      ) : (
        <div className={s.DocumentsPage}>
          <PageSearch
            query={search}
            setQuery={(val) => {
              setSearch(val);
              if (timeout.current) {
                clearTimeout(timeout.current);
              }
              timeout.current = setTimeout(() => setUrlSearch(val), val ? SEARCH_DELAY : 0);
            }}
            count={urlSearch ? data?.dashboard.documents.pagination.totalCount : undefined}
          />
          <DocumentList
            documents={data?.dashboard.documents.items}
            documentTypes={documentTypes}
            onDocumentTypesChange={documentTypesChangeHandler}
            loading={[NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus)}
            fetchingMore={networkStatus === NetworkStatus.fetchMore}
            onFetchMore={
              data &&
              data.dashboard.documents.pagination.totalCount > data.dashboard.documents.items.length &&
              networkStatus !== NetworkStatus.fetchMore
                ? onFetchMore
                : undefined
            }
          />
        </div>
      )}
    </React.Fragment>
  );
}
