import React, { useState } from 'react';
import clsx from 'clsx';
import { Outlet } from 'react-router-dom';
import { ScrollTopButton } from 'features/ScrollTopButton';
import { useAuthority } from 'store/authority';
import { AuthorityError } from './AuthorityError';
import { DepartmentList } from './DepartmentList';
import { Header } from './Header';
import { Loading } from './Loading';
import { NavBar } from './NavBar';
import s from './UserLayout.module.scss';

export function UserLayout() {
  const { loading, error, department, availableDepartments } = useAuthority();
  const [departmentList, setDepartmentList] = useState(false);
  const header = availableDepartments.length > 1;

  if (loading) return <Loading />;
  if (error) return <AuthorityError error={error} />;

  return (
    <React.Fragment>
      {header && (
        <Header
          department={department}
          departmentList={departmentList}
          showDepartmentList={() => setDepartmentList(true)}
        />
      )}

      {departmentList && <DepartmentList onClose={() => setDepartmentList(false)} />}

      <main className={clsx(s.UserLayout, !header && s.UserLayout_noHeader)}>
        <Outlet />
      </main>

      <ScrollTopButton />
      <NavBar />
    </React.Fragment>
  );
}
