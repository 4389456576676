import { basename } from 'store/basename';

const r = (PDF: string): [string, string] => [encodeURI(`${basename}/rating/${PDF}`), PDF];

export function getRatingIndicatorPDF(indicatorId: number): [string, string] {
  switch (indicatorId) {
    case 1:
      // ФГИС ЕИП НСУД
      return r('НСУД.pdf');
    case 2:
      // Устранение нарушений
      return r('Устранение выявленных нарушений.pdf');
    case 3:
      // ПОС
      return r('Платформа обратной связи.pdf');
    case 4:
      // Доля услуг в эл.виде на ЕПГУ
      return r('Доля услуг в электронном виде.pdf');
    case 5:
      // Уровень удовлетворенности клиентов
      return r('Уровень удовлетворенности клиентов по услугам ведомства.pdf');
    case 6:
      // Процент неактуальных начислений на ЕПГУ
      return r('Процент актуальных начислений.pdf');
    case 7:
      // Услуги в режиме онлайн
      return r('Перевод услуг в режим онлайн.pdf');
    case 8:
      // Доля ЦАР
      return r('Доля ЦАР.pdf');
    case 9:
      // Эксплуатация СМЭВ
      return r('Эксплуатация СМЭВ.pdf');
    case 10:
      // Стратегии госкомпаний
      return r('Стратегии госкомпаний.pdf');
    case 11:
      // ИИ-зрелость
      return r('ИИ-зрелость.pdf');
    case 12:
      // Импортонезависимость ПО
      return r('Уровень импортонезависимости ПО.pdf');
    case 13:
      // Госпаблики
      return r('Госпаблики.pdf');
    case 14:
      // Кассовое исполнение
      return r('Кассовое исполнение.pdf');
    case 15:
      // переход на КГ АРМ ГС
      return r('Индекс перехода на АРМ ГС.pdf');
    case 16:
      // использования АРМ ГС
      return r('Индекс использования АРМ ГС.pdf');
    case 17:
      // ГосКлюч
      return r('Госключ.pdf');
    case 21:
      // Доля ИС, передающих результаты услуг на ЕПГУ
      return r('Доля информационных систем.pdf');
    case 22:
      // ГосОблако
      return r('Утилизация ресурсов и услуг ГосОблака.pdf');
    default:
      return r('Общая методика проведения оценки ФРЦТ.pdf');
  }
}
