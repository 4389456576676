import React, { forwardRef } from 'react';
import s from './ShareLayout.module.scss';

interface ShareLayoutProps {
  children: React.ReactNode;
}

export const ShareLayout = forwardRef<HTMLDivElement, ShareLayoutProps>(({ children }, ref) => {
  return (
    <div ref={ref} className={s.ShareLayout}>
      {children}
    </div>
  );
});
