import React from 'react';
import { Divider } from 'components/Divider';
import { ShareButton } from 'components/ShareButton';
import { BudgetCardContent, BudgetCardContentData } from 'features/BudgetCardContent';
import { SharePDF, SharePDFKind } from 'features/SharePDF';
import { WidgetCard } from 'features/Widgets/_components/WidgetCard';
import s from './BudgetCard.module.scss';

export function BudgetCard({ budget }: { budget: BudgetCardContentData }) {
  return (
    <WidgetCard to={'/budget'}>
      <BudgetCardContent className={s.BudgetCard__content} budget={budget} />

      <Divider className={s.BudgetCard__divider} />
      <SharePDF kind={SharePDFKind.BudgetCard} data={budget}>
        {({ share, isLoading }) => <ShareButton share={share} isLoading={isLoading} />}
      </SharePDF>
    </WidgetCard>
  );
}
