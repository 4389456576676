import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Icon, IconSize } from 'components/Icon';
import { Label, LabelType } from 'components/Label';
import s from './Indicator.module.scss';

export interface IndicatorProps {
  to: string;
  code: string;
  status: LabelType;
  statusLabel: string;
  children: string;
}

export function Indicator({ to, code, status, statusLabel, children }: IndicatorProps) {
  return (
    <Link className={s.Indicator} to={to}>
      <div className={s.Indicator__content}>
        <div className={s.Indicator__name}>
          <span className={s.Indicator__code}>{`${code} `}</span>
          <span>{children}</span>
        </div>

        <Label type={status}>{statusLabel}</Label>
      </div>

      <Icon className={s.Indicator__arrowRight} icon={ArrowRight} size={IconSize.Small} />
    </Link>
  );
}
