import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import s from './Filter.module.scss';

export interface FilterButtonProps {
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}

export const FilterButton: FC<FilterButtonProps> = ({ className, onClick, children }) => {
  return (
    <button className={clsx(s.FilterButton, className)} onClick={onClick}>
      {children}
    </button>
  );
};
