import React, { Fragment, useEffect, useRef, useState } from 'react';
import { formatDate } from '@digital-gov/ui-utils';
import { Divider } from 'components/Divider';
import { Loader, LoaderSize } from 'components/Loader';
import { Placeholder, PlaceholderVariant } from 'components/Placeholder';
import { EventsListQuery } from 'store/graphql';
import { EventCard } from './EventCard';
import { Tags, TagsProps } from './Tags';
import { useCreateEventsGroups } from './useCreateEventsGroups';
import s from './EventsList.module.scss';

export interface EventsListProps {
  data?: EventsListQuery;
  tags?: TagsProps['tags'];
  loading?: boolean;
  fetchingMore?: boolean;
  tagsFilter: TagsProps['selectedTags'];
  onSelectedTagsChange: TagsProps['onSelectedTagsChange'];
  onFetchMore?: () => void;
}

export const EventsList = ({
  data,
  tags,
  loading,
  fetchingMore,
  tagsFilter,
  onSelectedTagsChange,
  onFetchMore
}: EventsListProps) => {
  const [showAllTags, setShowAllTags] = useState(false);

  const eventsGroups = useCreateEventsGroups(data?.dashboard.events);

  const hasData = eventsGroups.length > 0;
  const todayFormatted = formatDate(new Date(), 'dd.MM.yyyy');
  const endOfListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!endOfListRef.current || !onFetchMore) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => entry.isIntersecting && onFetchMore?.());

    observer.observe(endOfListRef.current);
    return () => observer.disconnect();
  }, [onFetchMore]);

  const tagsComponent = !!tags?.length && (
    <Tags
      tags={tags}
      showAll={showAllTags}
      onChangeShowAll={setShowAllTags}
      selectedTags={tagsFilter}
      onSelectedTagsChange={onSelectedTagsChange}
    />
  );

  return (
    <>
      {!!tags?.length && (!hasData || eventsGroups[0]?.[0]?.important) && (
        <div className={s.EventsList__block}>{tagsComponent}</div>
      )}

      {loading && !data ? (
        <Loader />
      ) : (
        !hasData && (
          <div className={s.EventsList__block}>
            <Placeholder variant={PlaceholderVariant.DocumentsTetriary}>На {todayFormatted} нет событий</Placeholder>
          </div>
        )
      )}

      {eventsGroups.map((group, index) => (
        <Fragment key={index}>
          {group.length === 1 && group[0].important ? (
            <EventCard
              id={group[0].id}
              header={group[0].header}
              important={group[0].important}
              publishDate={group[0].publishDate}
              tags={group[0].tags}
            />
          ) : (
            <div className={s.EventsList__block}>
              {!!tags?.length && index === 0 && !group[0].important && <>{tagsComponent}</>}

              {group.map(({ id, important, header, publishDate, tags }, index) => (
                <Fragment key={id}>
                  <EventCard
                    id={id}
                    variant={'joint'}
                    header={header}
                    important={important}
                    publishDate={publishDate}
                    tags={tags}
                  />
                  {index < group.length - 1 && <Divider />}
                </Fragment>
              ))}
            </div>
          )}
        </Fragment>
      ))}

      {fetchingMore && <Loader className={s.EventsList__loader} size={LoaderSize.Medium} />}

      {onFetchMore && <div ref={endOfListRef} />}
    </>
  );
};
