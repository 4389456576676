import React from 'react';
import clsx from 'clsx';
import { LabelType } from 'components/Label';
import s from './Label.module.scss';

export { LabelType };

export interface LabelProps {
  className?: string;
  type?: LabelType;
  children: string;
}

// обычный components/Label не отображается при генерации PDF,
// так что это его упрощенная версия
export function Label({ className, type = LabelType.Neutral, children }: LabelProps) {
  return <div className={clsx(s.Label, s[`Label_${type}`], className)}>{children}</div>;
}
