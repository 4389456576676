import { compareAsc } from 'date-fns';
import { tryParseISO } from './tryParseISO';
/**
 * Создает компаратор для сравнения строк, содержащих дату в формате ISO
 * @param dir - направление сортировки (1 для asc, -1 для desc)
 */
export const compareDateStrings = (dir = 1) => (a, b) => {
    const aDate = a && tryParseISO(a);
    const bDate = b && tryParseISO(b);
    if (aDate && bDate) {
        return compareAsc(aDate, bDate) * dir;
    }
    if (!aDate)
        return 1;
    if (!bDate)
        return -1;
    return 0;
};
