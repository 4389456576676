import React, { useState } from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { ErrorMessage } from 'components/ErrorMessage';
import { ArrowLeft, Icon } from 'components/Icon';
import s from './PDFViewer.module.scss';

export interface PDFViewerProps {
  title?: string;
  slotRight?: React.ReactNode;
  href: string;
  open: boolean;
  onClose: () => void;
}

export const PDFViewer = ({ title, slotRight, href, open, onClose }: PDFViewerProps) => {
  const [error, setError] = useState(false);

  if (!open) return null;

  const content = (
    <div className={s.PDFViewer}>
      <div className={s.PDFViewer__head}>
        <div className={s.PDFViewer__headContent}>
          <Icon className={s.PDFViewer__closeButton} icon={ArrowLeft} onClick={onClose} />
          {title && <div className={s.PDFViewer__title}>{title}</div>}

          {!!slotRight && <div className={s.PDFViewer__slotRight}>{slotRight}</div>}
        </div>
      </div>
      <div
        className={clsx(s.PDFViewer__content, {
          [s.PDFViewer__content_error]: error
        })}>
        {error ? (
          <ErrorMessage description={'Ошибка загрузки документа'} />
        ) : (
          <iframe className={s.PDFViewer__iframe} title={title || ''} src={href} onError={() => setError(true)} />
        )}
      </div>
    </div>
  );

  return createPortal(content, document.body);
};
