import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth, getAuthTarget } from '@digital-gov/auth-apollo-store';
import { useScrollTopOnRouteChange } from '@digital-gov/ui-utils';
import { basename } from 'store/basename';
import { UserLayout } from './_layouts';
// import { BudgetDirectionPage, BudgetPage } from './budget';
import { DocumentsPage, DocumentPage } from './documents';
import { EventPage, EventsPage } from './events';
import { HomePage } from './home';
import { IndicatorPage, IndicatorsPage } from './indicators';
import { LibraryRoutes } from './library';
import { LoginPage } from './login';
import { PrivacyPage } from './privacy';
import { ProfilePage } from './profile';
import { RatingIndicatorPage, RatingPage } from './rating';
import { RequestPage } from './request';
import { ServicesRoutes } from './services';
import { SystemsPage } from './systems';

export function AppRoutes() {
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();

  useScrollTopOnRouteChange();

  useEffect(() => {
    // Yandex.Metrika
    // @ts-ignore
    window.ym?.('93813966', 'hit', pathname);
  }, [pathname]);

  return (
    <Routes>
      {/* Google Play */}
      <Route path={'/privacy'} element={<PrivacyPage />} />

      {isLoggedIn() ? (
        <React.Fragment>
          <Route element={<UserLayout />}>
            <Route path={'/'} element={<HomePage />} />
            <Route path={'/request/:requestId'} element={<RequestPage />} />
            <Route path={'/rating'}>
              <Route index element={<RatingPage />} />
              <Route path={':indicatorId'} element={<RatingIndicatorPage />} />
            </Route>
            <Route path={'/indicators'}>
              <Route index element={<IndicatorsPage />} />
              <Route path={':indicatorId'} element={<IndicatorPage />} />
            </Route>
            {/* <Route path={'/budget'}> */}
            {/*  <Route index element={<BudgetPage />} /> */}
            {/*  <Route path={':directionId/:programId?/:projectId?'} element={<BudgetDirectionPage />} /> */}
            {/* </Route> */}
            <Route path={'/documents'}>
              <Route index element={<DocumentsPage />} />
              <Route path={':documentId'} element={<DocumentPage />} />
            </Route>
            <Route path={'/systems'} element={<SystemsPage />} />
            <Route path={'/events'}>
              <Route index element={<EventsPage />} />
              <Route path={':eventId'} element={<EventPage />} />
            </Route>
            <Route path={'/services/*'} element={<ServicesRoutes />} />
            <Route path={'/library/*'} element={<LibraryRoutes />} />
            <Route path={'/profile'} element={<ProfilePage />} />
          </Route>
          <Route path={'/auth'} element={<Navigate to={getAuthTarget(basename)} replace />} />
          <Route path={'*'} element={<Navigate to={'/'} replace />} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Route path={'/login'} element={<LoginPage />} />
          <Route path={'/auth'} element={<Navigate to={'/login'} replace />} />
          <Route path={'*'} element={<Navigate to={`/login?target=${window.location.pathname}`} replace />} />
        </React.Fragment>
      )}
    </Routes>
  );
}
