import React from 'react';
import clsx from 'clsx';
import s from './Card.module.scss';

export interface CardProps {
  title: string;
  slotRight?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export function Card({ title, slotRight, children, className }: CardProps) {
  return (
    <div className={clsx(s.Card, className)}>
      <div className={s.Card__header}>
        <h3 className={s.Card__heading}>{title}</h3>
        {slotRight}
      </div>

      <div className={s.Card__content}>{children}</div>
    </div>
  );
}
