import { useQueryParams } from '@digital-gov/ui-utils';
import { URL_KEY_EVENTS_TAGS } from '../urlKeys';

export const useQueryTags = () =>
  useQueryParams(URL_KEY_EVENTS_TAGS, null, {
    getter: (value): number[] | null => {
      const valueArray: number[] = [];

      value?.split(',').forEach((val) => {
        if (Number(val)) {
          valueArray.push(Number(val));
        }
      });

      return valueArray.length > 0 ? valueArray : null;
    },
    setter: (value) => {
      return value?.join(',') ?? '';
    }
  });
