import React from 'react';
import clsx from 'clsx';
import { ReactComponent as Documents } from './documents.svg';
import s from './Placeholder.module.scss';

export enum PlaceholderVariant {
  Documents = 'documents',
  DocumentsSecondary = 'documentsSecondary',
  DocumentsTetriary = 'documentsTetriary'
}

export interface PlaceholderProps {
  variant: PlaceholderVariant;
  children: React.ReactNode;
  className?: string;
}

export function Placeholder({ variant, children, className }: PlaceholderProps) {
  return (
    <div className={clsx(s.Placeholder, className)}>
      {variant === PlaceholderVariant.Documents && <Documents style={{ color: '#16a086' }} />}
      {variant === PlaceholderVariant.DocumentsSecondary && <Documents style={{ color: '#949CA5', opacity: 0.3 }} />}
      {variant === PlaceholderVariant.DocumentsTetriary && <Documents style={{ color: '#949CA5', opacity: 1 }} />}
      <div className={s.Placeholder__message}>{children}</div>
    </div>
  );
}
