import React from 'react';
import { KeyValuePair } from 'components/KeyValuePair';
import { RatioBar } from 'components/RatioBar';
import { KeyIndicatorsType } from 'store/graphql';
import { ExportInfo } from '../_components/ExportInfo';
import s from './IndicatorsCard.module.scss';

export type IndicatorsCardData = Pick<
  KeyIndicatorsType,
  'total' | 'key' | 'achievedOrApproved' | 'failedOrDisagreement'
>;

interface IndicatorsCardProps {
  indicators: IndicatorsCardData;
}

export function IndicatorsCard({ indicators }: IndicatorsCardProps) {
  return (
    <React.Fragment>
      <div className={s.IndicatorsCard__header}>
        <h3 className={s.IndicatorsCard__heading}>Ключевые показатели</h3>
        <div className={s.IndicatorsCard__indicators}>{indicators.key}</div>
      </div>

      <ExportInfo />

      <RatioBar
        className={s.IndicatorsCard__ratioBar}
        data={[
          { value: indicators.achievedOrApproved, color: '#16a086' },
          { value: indicators.failedOrDisagreement, color: '#ee3f58' },
          { value: indicators.total - indicators.key }
        ]}
      />

      <div className={s.IndicatorsCard__pairs}>
        <KeyValuePair valueBold pointColor={'#16a086'} keyLabel={'Утверждено'}>
          {indicators.achievedOrApproved}
        </KeyValuePair>
        <KeyValuePair valueBold pointColor={'#ee3f58'} keyLabel={'Разногласия'}>
          {indicators.failedOrDisagreement}
        </KeyValuePair>
        <KeyValuePair pointColor={'#d1d5df'} keyLabel={'Показателей ВПЦТ'}>
          {indicators.total}
        </KeyValuePair>
      </div>
    </React.Fragment>
  );
}
