import React, { useCallback } from 'react';
import { BottomSheet } from 'components/BottomSheet';
import { BottomSheetProps } from 'components/BottomSheet';
import { Button, ButtonVariant } from 'components/Button';
import s from './Dialog.module.scss';

export type DialogPropsActionType<P> = (extProps?: P) => void | Promise<void>;

export type DialogPropsType<P> = Pick<BottomSheetProps, 'title'> & {
  description?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  cancelOnClose?: boolean;
  onConfirm?: DialogPropsActionType<P>;
  onCancel?: DialogPropsActionType<P>;
  extProps?: P;
};

export interface DialogProps<P> extends DialogPropsType<P>, Pick<BottomSheetProps, 'onClose'> {}

export const Dialog = <P,>({
  description,
  confirmButtonText = 'Да',
  cancelButtonText = 'Нет',
  cancelOnClose = true,
  onConfirm,
  onCancel,
  onClose,
  extProps,
  ...props
}: DialogProps<P>) => {
  const showConfirmButton = !!(onConfirm || (!onConfirm && !onCancel));
  const showCancelButton = !!onCancel;

  const handleConfirm = useCallback(() => {
    onConfirm?.(extProps);
    onClose?.();
  }, [onConfirm, onClose, extProps]);

  const handleCancel = useCallback(() => {
    onCancel?.(extProps);
    onClose?.();
  }, [onCancel, onClose, extProps]);

  return (
    <BottomSheet onClose={cancelOnClose ? handleCancel : onClose} {...props}>
      <div className={s.Dialog}>
        {description && <div className={s.Dialog__description}>{description}</div>}

        <div className={s.Dialog__actions}>
          {showConfirmButton && <Button onClick={handleConfirm}>{confirmButtonText}</Button>}
          {showCancelButton && (
            <Button variant={ButtonVariant.Secondary} onClick={handleCancel}>
              {cancelButtonText}
            </Button>
          )}
        </div>
      </div>
    </BottomSheet>
  );
};
