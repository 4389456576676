import React from 'react';
import s from './EventContent.module.scss';

export function EventContent({ content }: { content: string }) {
  return (
    <div className={s.EventContent}>
      {content.split('\n').map((text, index) => {
        const __html = text.replace(/\b(https?:\/\/\S+)/gm, '<a href="$1" target="_blank">$1</a>');
        return <p key={index} dangerouslySetInnerHTML={{ __html }} />;
      })}
    </div>
  );
}
