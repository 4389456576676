import React from 'react';
import clsx from 'clsx';
import { Dismiss } from 'components/Icon';
import { Overlay } from 'components/Overlay';
import { RoundIconButton, RoundIconButtonSize } from 'components/RoundIconButton';
import s from './BottomSheet.module.scss';

export interface BottomSheetProps {
  className?: string;
  title?: string;
  showCloseButton?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

export function BottomSheet({ className, title, showCloseButton = true, onClose, children }: BottomSheetProps) {
  const onClosePress = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (onClose) onClose();
  };

  return (
    <Overlay
      classes={{ root: s.BottomSheet__overlay, backdrop: s.BottomSheet__overlayBackdrop }}
      onClick={onClosePress}>
      <div className={clsx(s.BottomSheet, className)} onClick={(e) => e.preventDefault()}>
        <div className={s.BottomSheet__content}>
          {(title || showCloseButton) && (
            <div className={s.BottomSheet__header}>
              {title && <h2 className={s.BottomSheet__heading}>{title}</h2>}
              {showCloseButton && (
                <RoundIconButton
                  size={RoundIconButtonSize.Small}
                  icon={Dismiss}
                  onClick={onClosePress}
                  className={s.BottomSheet__close}
                />
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </Overlay>
  );
}
