import React from 'react';
import { KeyValuePair } from 'components/KeyValuePair';
import { RatioBar } from 'components/RatioBar';
import { FinancialAnalysisType } from 'store/graphql';
import { formatBudget } from 'utils/formatBudget';
import { ExportInfo } from '../_components/ExportInfo';
import s from './BudgetCard.module.scss';

export type BudgetCardData = Pick<
  FinancialAnalysisType,
  'budget' | 'acceptedBudgetCommitments' | 'cashExecution' | 'remainder'
>;

interface BudgetCardProps {
  budget: BudgetCardData;
}

export function BudgetCard({ budget }: BudgetCardProps) {
  return (
    <React.Fragment>
      <div className={s.BudgetCard__header}>
        <h2 className={s.BudgetCard__heading}>
          <span>Бюджет,</span>
          <span className={s.BudgetCard__unit}> млн</span>
        </h2>

        <div className={s.BudgetCard__budget}>{formatBudget(budget.budget)}</div>
      </div>

      <ExportInfo />

      <div className={s.BudgetCard__cashExecution}>{`Касса, ${formatBudget(budget.cashExecution)}`}</div>
      <RatioBar
        className={s.BudgetCard__ratioBar}
        markPercent={(budget.cashExecution / budget.budget) * 100}
        data={[
          { value: budget.acceptedBudgetCommitments, color: '#16a086' },
          { value: budget.remainder, color: '#f8b200' }
        ]}
      />

      <div className={s.BudgetCard__pairs}>
        <KeyValuePair valueBold pointColor={'#f8b200'} keyLabel={'Свободный остаток'}>
          {formatBudget(budget.remainder)}
        </KeyValuePair>
        <KeyValuePair valueBold pointColor={'#16a086'} keyLabel={'Принятые БО'}>
          {formatBudget(budget.acceptedBudgetCommitments)}
        </KeyValuePair>
      </div>
    </React.Fragment>
  );
}
