import { BottomSheet, Filter, FilterRadio } from 'components/BottomSheet';
import { SortDirectionEnum } from 'store/graphql';
import { sortOptions } from './constants';
import s from './EventsSort.module.scss';

export interface EventsSortProps {
  sort: SortDirectionEnum;
  onClose: () => void;
  onChange: (value: SortDirectionEnum) => void;
}

export const EventsSort = ({ sort, onChange, onClose }: EventsSortProps) => {
  return (
    <BottomSheet className={s.EventsSort__bottomSheet} onClose={onClose} showCloseButton={false}>
      <Filter title={'Сортировать по'}>
        <FilterRadio value={sort} options={sortOptions} onChange={onChange} />
      </Filter>
    </BottomSheet>
  );
};
