import React from 'react';
import { Divider } from 'components/Divider';
import { KeyIndicatorType } from 'store/graphql';
import { ExportInfo } from '../_components/ExportInfo';
import { Label, LabelType } from '../_components/Label';
import s from './Indicators.module.scss';

export type IndicatorsData = Pick<KeyIndicatorType, 'id' | 'code' | 'name' | 'approved'>[];

interface IndicatorsProps {
  indicators: IndicatorsData;
}

export function Indicators({ indicators }: IndicatorsProps) {
  return (
    <React.Fragment>
      <h3 className={s.Indicators__heading}>Показатели</h3>
      <ExportInfo />

      <div className={s.Indicators__list}>
        {indicators.map(({ id, code, name, approved }, index) => (
          <React.Fragment key={id}>
            <div className={s.Indicator__name}>
              <span className={s.Indicator__code}>{`${code} `}</span>
              <span>{name}</span>
            </div>

            {approved ? (
              <Label type={LabelType.Success}>Утверждено</Label>
            ) : (
              <Label type={LabelType.Error}>Разногласия</Label>
            )}

            {index !== indicators.length - 1 && <Divider className={s.Indicator__divider} />}
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
}
