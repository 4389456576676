import React from 'react';
import clsx from 'clsx';
import { ArrowDown, Icon, IconSize } from 'components/Icon';
import s from './DropdownArrow.module.scss';

export interface DropdownArrowProps {
  isOpen?: boolean;
  small?: boolean;
}

export function DropdownArrow({ isOpen, small }: DropdownArrowProps) {
  return (
    <Icon
      icon={ArrowDown}
      size={small ? IconSize.Small : IconSize.Medium}
      className={clsx(s.DropdownArrow, { [s.DropdownArrow_isOpen]: isOpen })}
    />
  );
}
