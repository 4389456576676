import { isSameDay } from 'date-fns';
import { orderBy } from 'lodash-es';
import { LibraryMaterialType } from 'store/graphql';
import { addSortIndexForItems } from './_components/LibraryList/sort';
import { LibrarySort } from './library.types';

export type LibraryFSTreeNodeType = {
  id: string;
  name: string;
  path: string[];
  updatedAt?: string;
  fileProps?: LibraryMaterialType;
};

export class LibraryFSTreeNode {
  id: string;
  name: string;
  path: string[];
  updatedAt?: string;
  fileProps?: LibraryMaterialType;
  nodes: LibraryFSTreeNode[];

  constructor({ id, name, path, updatedAt, fileProps }: LibraryFSTreeNodeType) {
    this.id = id;
    this.name = name;
    this.path = path;
    this.updatedAt = updatedAt;
    this.fileProps = fileProps;
    this.nodes = [];
  }
}

export type LibraryFSTreeFolderType = Omit<LibraryFSTreeNodeType, 'fileProps'> & {
  nodes: LibraryFSTreeNode[];
};

export type LibraryFSTreeFileType = Omit<LibraryFSTreeNodeType, 'fileProps' | 'updatedAt'> & {
  updatedAt: string;
  fileProps: LibraryMaterialType;
};

export type LibraryNodesListType = {
  folders: LibraryFSTreeFolderType[];
  files: LibraryFSTreeFileType[];
};

const sortFields = {
  [LibrarySort.Name]: {
    folders: [
      ['index', 'name'],
      ['asc', 'asc']
    ],
    files: [
      ['index', 'name'],
      ['asc', 'asc']
    ]
  },
  [LibrarySort.Date]: {
    folders: [['updatedAt'], ['desc']],
    files: [['updatedAt'], ['desc']]
  }
};

export class LibraryUtils {
  static buildFSTree(files: LibraryMaterialType[]): { fsTree: LibraryFSTreeNode; updatedAt: string | null } {
    let folderIndex = 0;
    const root = new LibraryFSTreeNode({
      id: String(folderIndex),
      name: '',
      path: []
    });

    files.forEach((file) => {
      let currentNode = root;
      const folderPath: string[] = [];

      file.path.forEach((directory) => {
        let childNode = currentNode.nodes.find((node) => node.name === directory);

        if (!childNode) {
          ++folderIndex;
          const folderId = String(folderIndex);
          folderPath.push(folderId);
          childNode = new LibraryFSTreeNode({
            id: folderId,
            name: directory,
            path: folderPath.slice()
          });
          currentNode.nodes.push(childNode);
        } else {
          folderPath.push(childNode.id);
        }

        currentNode = childNode;
      });

      const fileNode = new LibraryFSTreeNode({
        id: `file-${file.id}`,
        name: file.fileName,
        path: folderPath,
        updatedAt: file.updatedAt,
        fileProps: file
      });
      currentNode.nodes.push(fileNode);
    });

    let latestUpdatedAt: null | string = null;

    function setFoldersDates(treeNode: LibraryFSTreeNode) {
      treeNode.nodes.forEach((node) => {
        if (node.nodes.length) {
          const nodeFiles = LibraryUtils.searchFilesInTree(node);
          const latestFileDate = orderBy(nodeFiles, ['updatedAt'], ['desc'])[0]?.updatedAt;
          if (latestFileDate) {
            node.updatedAt = latestFileDate;

            if (!latestUpdatedAt || latestFileDate > latestUpdatedAt) {
              latestUpdatedAt = latestFileDate;
            }
          }
          setFoldersDates(node);
        }
      });
    }
    setFoldersDates(root);

    return {
      fsTree: root,
      updatedAt: latestUpdatedAt
    };
  }

  static getTreeByPath(path: string[], node: LibraryFSTreeNode): LibraryFSTreeNode | undefined {
    if (!path.length) {
      return node;
    }

    const [dirId, ...restPath] = path;
    const childNode = node.nodes.find((child) => child.id === dirId);

    if (childNode && childNode.nodes.length && !childNode.fileProps) {
      return LibraryUtils.getTreeByPath(restPath, childNode);
    }

    return undefined;
  }

  static countInnerNodes(node: LibraryFSTreeNode): { folders: number; files: number } {
    let folders = 0;
    let files = 0;

    for (const childNode of node.nodes) {
      if (childNode.nodes.length && !childNode.fileProps) {
        folders++;
        const { folders: childFolders, files: childFiles } = LibraryUtils.countInnerNodes(childNode);
        folders += childFolders;
        files += childFiles;
      } else {
        files++;
      }
    }

    return { folders, files };
  }

  static search(value: string, node: LibraryFSTreeNode): LibraryFSTreeNode[] {
    if (!value) return [];

    let matchingNodes: LibraryFSTreeNode[] = [];

    for (const childNode of node.nodes) {
      if (childNode.nodes.length && !childNode.fileProps) {
        matchingNodes = matchingNodes.concat(LibraryUtils.search(value, childNode));
      }
      if (
        childNode.name.toLowerCase().includes(value.toLowerCase()) ||
        childNode.fileProps?.fileName.toLowerCase().includes(value.toLowerCase())
      ) {
        matchingNodes.push(childNode);
      }
    }

    return matchingNodes;
  }

  static searchFilesInTree(node: LibraryFSTreeNode): LibraryFSTreeNode[] {
    let matchingNodes: LibraryFSTreeNode[] = [];

    for (const childNode of node.nodes) {
      if (childNode.nodes.length && !childNode.fileProps) {
        matchingNodes = matchingNodes.concat(LibraryUtils.searchFilesInTree(childNode));
      } else {
        matchingNodes.push(childNode);
      }
    }

    return matchingNodes;
  }

  static searchNewFiles(node: LibraryFSTreeNode, date: string): LibraryFSTreeNode[] {
    const cDate = new Date(date);

    return LibraryUtils.searchFilesInTree(node).filter((file) => {
      return file.updatedAt && isSameDay(new Date(file.updatedAt), cDate);
    });
  }

  static searchFavoriteFiles(node: LibraryFSTreeNode): LibraryFSTreeNode[] {
    return LibraryUtils.searchFilesInTree(node).filter((file) => {
      return (file as LibraryFSTreeFileType).fileProps.favorite;
    });
  }

  static searchFilesByTagId(node: LibraryFSTreeNode, tagId: number): LibraryFSTreeNode[] {
    return LibraryUtils.searchFilesInTree(node).filter((file) => {
      return !!(file as LibraryFSTreeFileType).fileProps.tags.find((t) => t.id === tagId);
    });
  }

  static sortNodes(nodes: LibraryFSTreeNode[], sort: LibrarySort): LibraryNodesListType {
    if (!nodes.length) {
      return {
        folders: [],
        files: []
      };
    }

    let folders = nodes.filter((n) => !n.fileProps) || [];
    let files = nodes.filter((n) => !!n.fileProps) || [];

    folders = addSortIndexForItems(folders);
    files = addSortIndexForItems(files);

    return {
      folders: !folders.length ? [] : orderBy(folders, ...sortFields[sort].folders),
      files: !files.length ? [] : (orderBy(files, ...sortFields[sort].files) as LibraryFSTreeFileType[])
    };
  }
}
