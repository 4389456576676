import { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { DATETIME_SHORT, formatDate, formatFileSize, formatName, tryNumberLike } from '@digital-gov/ui-utils';
import { BottomSheet } from 'components/BottomSheet';
import { Divider } from 'components/Divider';
import { Download, Icon } from 'components/Icon';
import { Loader, LoaderSize } from 'components/Loader';
import { TagDotsStack } from 'components/TagDot';
import { FileTypeEnum, TagType, useGetFilePresignedUrlLazyQuery } from 'store/graphql';
import { downloadFileMobileRN, rnMessenger } from 'store/rnMessenger';
import { FileIcons } from './constants';
import { FileMenuAction } from './FileMenuAction';
import s from './File.module.scss';

export interface FileProps {
  fileId: number;
  fullName: string;
  fileName: string;
  fileNameOverflow?: boolean;
  fileType: FileTypeEnum;
  size: number;
  updatedAt?: string | null;
  dateFormatString?: string;
  fileInfoDivided?: boolean;
  fileInfoReverse?: boolean;
  tags?: TagType[];
  menuItems?: ReactNode;
}

export function File(props: FileProps) {
  const {
    fileId,
    fullName,
    fileName,
    fileNameOverflow = true,
    fileType,
    size,
    updatedAt,
    dateFormatString = DATETIME_SHORT,
    fileInfoDivided,
    fileInfoReverse,
    tags,
    menuItems
  } = props;
  const [menuActive, setMenuActive] = useState(false);

  const [getDownloadUrl, { loading }] = useGetFilePresignedUrlLazyQuery({
    fetchPolicy: 'network-only'
  });

  const onDownload = async () => {
    if (loading) return;

    setMenuActive(false);

    const entityId = tryNumberLike(fileId, null, true);
    if (entityId === null) {
      console.error('Неверный ID файла');
      return;
    }

    const { data } = await getDownloadUrl({ variables: { entityId } });
    const dwnldUrl = data?.filePresignedUrl;
    if (!dwnldUrl) {
      console.error('Ошибка запроса URL на скачивание файла');
      return;
    }

    if (rnMessenger.isActive) {
      return downloadFileMobileRN({
        fileType,
        fileName: fullName,
        downloadUrl: dwnldUrl
      });
    }

    const linkNode = document.createElement('a');
    linkNode.href = dwnldUrl;
    linkNode.click();
  };

  return (
    <>
      <div
        className={clsx(s.File, !loading && s.File_pointer)}
        onClick={menuItems ? () => setMenuActive(true) : onDownload}>
        <div className={s.File__content}>
          <div className={s.File__iconBox}>
            {loading ? (
              <Loader className={s.File__loader} size={LoaderSize.Small} />
            ) : (
              <Icon className={s.File__icon} icon={FileIcons[fileType]} />
            )}
          </div>

          <div className={s.File__contentMain}>
            <p className={clsx(s.File__name, fileNameOverflow && s.File__name_overflow)}>{formatName(fileName)}</p>
            <div
              className={clsx(
                s.File__info,
                fileInfoDivided && s.File__info_divided,
                fileInfoReverse && s.File__info_reverse,
                tags && s.File__info_marginTop
              )}>
              <span>{formatFileSize(size * 1000 * 1000)}</span>
              {fileInfoDivided && <span className={s.File__infoDivider} />}
              {updatedAt && <span>{formatDate(updatedAt, dateFormatString)}</span>}
            </div>
          </div>
        </div>

        {tags && <TagDotsStack colors={tags.map((t) => t.colorHex)} />}
      </div>

      {menuItems && menuActive && (
        <BottomSheet className={s.File__bottomSheet} onClose={() => setMenuActive(false)} showCloseButton={false}>
          <div className={s.File__menu}>
            {menuItems}
            <Divider />
            <FileMenuAction icon={Download} onClick={onDownload}>
              Скачать
            </FileMenuAction>
          </div>
        </BottomSheet>
      )}
    </>
  );
}
