import React from 'react';
import clsx from 'clsx';
import { DATE_FULL, useFormatDate } from '@digital-gov/ui-utils';
import { Indicator } from 'features/_rating/Indicator';
import { RatingDynamicsMap } from 'features/_rating/RatingDynamicsMap';
import { useIndicatorValue } from 'features/_rating/useIndicatorValue';
import { RatingDynamicEnum } from 'store/graphql';
import s from './RatingTitle.module.scss';

interface RatingSelectedProps {
  title: string;
  value: number;
  dynamics?: RatingDynamicEnum;
  lastUpdate?: string | null;
}

export function RatingTitle({ title, value, dynamics, lastUpdate }: RatingSelectedProps) {
  const lastUpdateFormatted = useFormatDate(lastUpdate, DATE_FULL);
  const indicatorValue = useIndicatorValue(value);
  return (
    <div className={s.RatingTitle}>
      <div className={clsx(s.RatingTitle__row, s.RatingTitle__rowText)}>
        <div>{title}</div>
        <div className={s.RatingTitle__spacer} />

        {dynamics ? (
          <Indicator
            position="left"
            content={<div className={s.RatingTitle__rowText}>{indicatorValue}</div>}
            showChangeIcon
            dynamics={RatingDynamicsMap[dynamics]}
          />
        ) : (
          <div>{indicatorValue}</div>
        )}
      </div>

      {lastUpdate ? <div className={s.RatingTitle__lastUpdate}>Обновлено {lastUpdateFormatted}</div> : null}
    </div>
  );
}
