import { useLocation, useParams } from 'react-router-dom';
import { PageError } from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { useEventQuery } from 'store/graphql';
import { Event } from './Event';

export function EventPage() {
  const { eventId } = useParams<{ eventId: string }>();

  const location = useLocation();

  const { data, loading, error } = useEventQuery({
    variables: {
      ids: [Number(eventId)]
    },
    skip: !Number(eventId)
  });

  if (loading) {
    return <Loader />;
  }

  const event = data?.dashboard.events.entries[0];

  if (error || !Number(eventId) || !event) {
    return <PageError />;
  }

  return (
    <>
      <PageTitle backTo={location.key !== 'default' ? 'prevPage' : '/events'}>События</PageTitle>

      <Event event={event} />
    </>
  );
}
