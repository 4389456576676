import React, { PropsWithChildren, useCallback, useState } from 'react';
import { useWidgetsQuery, WidgetSettingsInput, WidgetSettingsType } from 'store/graphql';
import { WidgetSettingsContext } from './WidgetSettingsContext';

export const WidgetSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [list, setList] = useState<WidgetSettingsType[]>([]);

  const { data, loading } = useWidgetsQuery();
  const widgetSettings = data?.dashboard.widgetSettings;

  const resetList = useCallback(() => {
    if (widgetSettings) setList([...widgetSettings]);
  }, [widgetSettings, setList]);

  const hideItem = (item: WidgetSettingsType) => {
    setList(list.map((el) => (item.id === el.id ? { ...el, visible: false } : el)));
  };

  const showItem = (item: WidgetSettingsType) => {
    setList(list.map((el) => (item.id === el.id ? { ...el, visible: true } : el)));
  };

  const serialize = (): WidgetSettingsInput[] => {
    const res: WidgetSettingsInput[] = [];
    for (const item of list) {
      if (item.changeable) {
        res.push({
          id: item.id,
          order: item.order,
          visible: item.visible
        });
      }
    }
    return res;
  };

  return (
    <WidgetSettingsContext.Provider
      value={{
        visible,
        loading,
        setVisible,
        settingsList: list,
        setList,
        resetList,
        hideItem,
        showItem,
        serialize
      }}>
      {children}
    </WidgetSettingsContext.Provider>
  );
};
