import React from 'react';
import clsx from 'clsx';
import s from './TagDot.module.scss';

export interface TagDotProps {
  className?: string;
  color?: string;
}

export const TagDot = ({ className, color }: TagDotProps) => {
  return <div className={clsx(s.TagDot, className)} style={{ backgroundColor: color || '#c5d1e0' }} />;
};

export interface TagDotsStackProps {
  className?: string;
  colors: string[];
}

export const TagDotsStack = ({ className, colors }: TagDotsStackProps) => {
  return (
    <div className={clsx(s.TagDotsStack, className)}>
      {colors.map((col, iCol) => (
        <div key={iCol} className={s.TagDotsStack__item} style={{ zIndex: colors.length - iCol }}>
          <TagDot className={s.TagDotsStack__dot} color={col} />
        </div>
      ))}
    </div>
  );
};
