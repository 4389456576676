import React from 'react';
import clsx from 'clsx';
import s from './RatioBar.module.scss';

export enum RatioBarSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export interface RatioBarProps {
  className?: string;
  size?: RatioBarSize;
  markPercent?: number;
  data: {
    value: number;
    color?: string;
  }[];
}

export function RatioBar({ className, size = RatioBarSize.Large, markPercent, data }: RatioBarProps) {
  const total = data.reduce((acc, { value }) => acc + value, 0);

  if (total === 0) {
    return null;
  } else {
    return (
      <div className={clsx(s.RatioBar__container, className)}>
        {typeof markPercent === 'number' && <div className={s.RatioBar__mark} style={{ left: `${markPercent}%` }} />}

        <div className={clsx(s.RatioBar, s[`RatioBar_${size}`])}>
          {data.map(({ color = '#d1d5df', value }) => (
            <div key={`${color}-${value}`} style={{ width: `${(value / total) * 100}%`, backgroundColor: color }} />
          ))}
        </div>
      </div>
    );
  }
}
