import React from 'react';
import clsx from 'clsx';
import s from './PageSheet.module.scss';

export interface PageSheetProps {
  className?: string;
  children?: React.ReactNode;
}

export const PageSheet = ({ className, children }: PageSheetProps) => {
  return <div className={clsx(s.PageSheet, className)}>{children}</div>;
};
