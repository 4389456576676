import { FilterRadioOptionsType } from 'components/BottomSheet';
import { Calendar2, Font } from 'components/Icon';
import { LibrarySort } from '../library.types';

export const sortOptions: FilterRadioOptionsType<LibrarySort>[] = [
  {
    label: 'Названию',
    value: LibrarySort.Name,
    icon: Font
  },
  {
    label: 'Дате изменения',
    value: LibrarySort.Date,
    icon: Calendar2
  }
];
