import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useGoBack } from '@digital-gov/ui-utils';
import { ArrowLeft, Icon } from 'components/Icon';
import s from './PageTitle.module.scss';

export interface PageTitleProps {
  backTo?: 'prevPage' | string;
  slotRight?: React.ReactNode;
  title?: string;
  children: string | React.ReactNode;
}

export const PageTitle: React.FC<PageTitleProps> = ({ backTo, children, slotRight, title }) => {
  const goBack = useGoBack(backTo === 'prevPage' ? undefined : backTo);
  const childrenStr = typeof children === 'string' ? children : '';
  return (
    <React.Fragment>
      <Helmet>
        <title>{title || childrenStr}</title>
      </Helmet>

      <div className={s.PageTitle}>
        {backTo && <Icon icon={ArrowLeft} className={s.PageTitle__back} onClick={() => goBack()} />}
        <h1 className={s.PageTitle__heading}>{children}</h1>

        {!!slotRight && <div className={s.PageTitle__slotRight}>{slotRight}</div>}
      </div>
    </React.Fragment>
  );
};
