import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import s from './Switcher.module.scss';

export interface SwitcherProps {
  className?: string;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean, e: ChangeEvent) => void;
}

export const Switcher = ({ className, value, disabled, onChange }: SwitcherProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    onChange(e.target.checked, e);
  };

  return (
    <label
      className={clsx(s.Switcher, className, {
        [s.Switcher_disabled]: disabled
      })}>
      <input
        className={s.Switcher__input}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={handleChange}
      />
      <div className={s.Switcher__box}>
        <div className={s.Switcher__switch} />
      </div>
    </label>
  );
};
