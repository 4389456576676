import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LibraryPage } from './LibraryPage';

export function LibraryRoutes() {
  return (
    <Routes>
      <Route path={'tags/:tagId?'} element={<LibraryPage />} />
      <Route path={'new'} element={<LibraryPage />} />
      <Route path={'favourite'} element={<LibraryPage />} />
      <Route path={'updateTags/:materialId?'} element={<LibraryPage />} />
      <Route path={'*'} element={<LibraryPage />} />
    </Routes>
  );
}
