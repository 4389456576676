import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import clsx from 'clsx';
import { useQueryParams } from '@digital-gov/ui-utils';
import { PageError } from 'components/ErrorMessage';
import { Icon, IconSize, Sort } from 'components/Icon';
import { Loader } from 'components/Loader';
import { PageSearch } from 'components/PageSearch';
import { PageTitle } from 'components/PageTitle';
import { SortDirectionEnum, useEventsListQuery, useEventsTagsQuery } from 'store/graphql';
import { EventsList } from './_components/EventsList';
import { EventsSort } from './_components/EventsSort';
import { useQuerySort } from './hooks/useQuerySort';
import { useQueryTags } from './hooks/useQueryTags';
import { URL_KEY_EVENTS_SEARCH } from './urlKeys';
import s from './EventsPage.module.scss';

const SEARCH_DELAY = 500;
const EVENTS_LIMIT = 20;

export function EventsPage() {
  const [urlSearch, setUrlSearch] = useQueryParams(URL_KEY_EVENTS_SEARCH);

  const [tagsFilter, setTagsFilter] = useQueryTags();
  const [sort, setSort] = useQuerySort();

  const [sortOpen, setSortOpen] = useState(false);
  const [search, setSearch] = useState(urlSearch ?? '');

  useEffect(() => {
    setSearch(urlSearch ?? '');
  }, [urlSearch]);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const { data: tags, loading: tagsLoading, error: tagsError } = useEventsTagsQuery();
  const { data, networkStatus, fetchMore, error } = useEventsListQuery({
    variables: {
      tagIds: tagsFilter,
      sort,
      search: urlSearch,
      offset: 0,
      limit: EVENTS_LIMIT
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });

  const onFetchMore = useCallback(() => {
    if (!data?.dashboard.events.entries) {
      return;
    }

    fetchMore({
      variables: {
        offset: data.dashboard.events.entries.length
      }
    });
  }, [data?.dashboard.events.entries, fetchMore]);

  if (tagsLoading || (networkStatus === NetworkStatus.loading && !data)) {
    return <Loader />;
  }

  if (tagsError || error) {
    return <PageError />;
  }

  const selectedTagsChangeHandler = (val: number[] | null) => {
    setTagsFilter(val);
  };

  return (
    <>
      <PageTitle
        backTo={'/'}
        slotRight={
          <Icon
            className={clsx(
              s.EventsPage__sortButton,
              sortOpen && s.EventsPage__sortButton_active,
              sort === SortDirectionEnum.Asc && s.EventsPage__sortButton_asc
            )}
            icon={Sort}
            size={IconSize.Medium}
            onClick={() => setSortOpen(!sortOpen)}
          />
        }>
        События
      </PageTitle>

      <div className={s.EventsPage}>
        <PageSearch
          query={search}
          setQuery={(val) => {
            setSearch(val);
            if (timeout.current) {
              clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(
              () => {
                setUrlSearch(val);
              },
              val ? SEARCH_DELAY : 0
            );
          }}
        />

        <EventsList
          data={data}
          tags={tags?.dashboard.events.tags}
          loading={networkStatus === NetworkStatus.setVariables}
          fetchingMore={networkStatus === NetworkStatus.fetchMore}
          tagsFilter={tagsFilter}
          onSelectedTagsChange={selectedTagsChangeHandler}
          onFetchMore={
            data &&
            data.dashboard.events.pagination.totalCount > data.dashboard.events.entries.length &&
            networkStatus !== NetworkStatus.fetchMore
              ? onFetchMore
              : undefined
          }
        />

        {sortOpen && (
          <EventsSort
            onClose={() => setSortOpen(false)}
            sort={sort}
            onChange={(value: SortDirectionEnum) => {
              setSort(value);
              setSortOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
}
