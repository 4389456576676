import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import { ArrowRight, Icon, IconSize } from 'components/Icon';
import { Label, LabelType } from 'components/Label';
import s from './Notification.module.scss';

export interface NotificationProps {
  to: string;
  kind: string;
  date: string;
  children: string;
  unread?: boolean;
  label: string;
  labelType: LabelType;
}

export function Notification({ children, unread, to, kind, date, label, labelType }: NotificationProps) {
  return (
    <Link to={to} className={s.Notification}>
      <div className={s.Notification__header}>
        <div className={s.Notification__kind}>{kind}</div>
        <div className={s.Notification__date}>{formatDate(date, DATE_SHORT_YEAR)}</div>
        <Icon icon={ArrowRight} size={IconSize.Small} />
      </div>

      <div className={clsx(s.Notification__content, unread && s.Notification__content_unread)}>{children}</div>
      <Label className={s.Notification__label} type={labelType}>
        {label}
      </Label>
    </Link>
  );
}
