import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Navigate, useParams } from 'react-router-dom';
import { EMDASH } from '@digital-gov/ui-utils';
import { Button, ButtonVariant } from 'components/Button';
import { DropdownArrow } from 'components/DropdownArrow';
import { PageError } from 'components/ErrorMessage';
import { Link, Share } from 'components/Icon';
import { Label, LabelType } from 'components/Label';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { ToggleTabs } from 'components/ToggleTabs';
import { Card, convertDataToShare, IndicatorTable, Position } from 'features/_indicators';
import { useDialog } from 'hooks/useDialog';
import { useExternalLink } from 'hooks/useExternalLink';
import { useShareTextData } from 'hooks/useShareTextData';
import {
  IndicatorAgreementDocument,
  useChangeDepartmentPositionMutation,
  useIndicatorAgreementQuery
} from 'store/graphql';
import { Services, SERVICES } from '../../services';
import { HistoryItem } from './HistoryItem';
import { NestedIndicator } from './NestedIndicator';
import s from './IndicatorPage.module.scss';

const title = SERVICES[Services.Indicator].title;
const backTo = `/services/${SERVICES[Services.Indicators].path}`;

enum TabEnum {
  Status = 'status',
  History = 'history'
}

const getLabel = (criteria: boolean): [LabelType, string] =>
  criteria ? [LabelType.Success, 'Утверждено'] : [LabelType.Error, 'Разногласия'];

export function IndicatorPage() {
  const [showNested, setShowNested] = useState(true);
  const [activeTab, setActiveTab] = useState(TabEnum.Status);
  const wasLoadedRef = useRef(false);
  const handleShareText = useShareTextData();
  const handleMailTo = useExternalLink();

  const { indicatorId } = useParams<{ indicatorId: string }>();
  const { loading, error, data } = useIndicatorAgreementQuery({
    variables: { ids: [+(indicatorId as string)] },
    notifyOnNetworkStatusChange: true
  });
  const indicator = data?.dashboard.keyIndicators.indicators[0];
  const history = indicator?.history;
  const initLoading = !wasLoadedRef.current && loading;

  const tabs = useMemo(() => {
    return [
      { label: 'Статус', value: TabEnum.Status },
      { label: 'История', value: TabEnum.History, disabled: !history?.length }
    ];
  }, [history]);

  // TODO: "canChangeOgvPosition = true" должно быть только у пользователя с ролью "Утверждающий РЦТ"
  const canChangeOgvPosition = true;
  const [changeOgvPosition, { loading: changeOgvPositionLoading }] = useChangeDepartmentPositionMutation({
    refetchQueries: [IndicatorAgreementDocument]
  });

  const handleChangePosition = useCallback(async () => {
    if (!indicator) return;

    try {
      await changeOgvPosition({
        variables: {
          keyIndicatorId: indicator.id
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [changeOgvPosition, indicator]);

  const [positionDialog, openPositionDialog] = useDialog({
    title: 'Позиция ведомства',
    description: 'Вы уверены, что хотите изменить позицию ведомства по показателю?',
    onConfirm: handleChangePosition,
    onCancel: () => {}
  });

  const handlePositionChange = (value: boolean, e: ChangeEvent) => {
    e.preventDefault();
    openPositionDialog();
  };

  const handleShare = () => {
    if (!indicator) return;
    handleShareText(convertDataToShare(indicator));
  };

  if (initLoading) return <Loader />;
  if (!data || !!error) return <PageError />;

  if (!indicator) return <Navigate to={backTo} />;

  wasLoadedRef.current = true;

  const [labelType, label] = getLabel(indicator.approved);
  const interactive = indicator.haveNested;
  const curators = indicator.curators;

  return (
    <React.Fragment>
      <PageTitle title={title} backTo={backTo}>
        {title}
      </PageTitle>

      <div className={s.IndicatorPage}>
        <ToggleTabs className={s.IndicatorPage__toggleTabs} options={tabs} value={activeTab} onChange={setActiveTab} />

        {activeTab === TabEnum.Status && (
          <Card title={'Статус'} slotRight={<Label type={labelType}>{label}</Label>}>
            <div className={s.Indicator__positions}>
              <Position approved={indicator.approvedFromMc}>Позиция Минцифры России</Position>
              <Position
                approved={indicator.approvedFromOgv}
                loading={changeOgvPositionLoading || loading}
                onChange={canChangeOgvPosition ? handlePositionChange : undefined}>
                Позиция ведомства
              </Position>
            </div>

            <div
              onClick={() => interactive && setShowNested((showNested) => !showNested)}
              className={clsx(s.Indicator__indicator, interactive && s.Indicator__indicator_interactive)}>
              <div className={s.Indicator__name}>
                <span className={s.Indicator__code}>{`${indicator.code} `}</span>
                <span>{indicator.name}</span>
              </div>

              {interactive && (
                <div className={s.Indicator__dropdownArrow}>
                  <DropdownArrow small isOpen={showNested} />
                </div>
              )}
            </div>

            {!indicator.haveNested && (
              <IndicatorTable
                className={s.Indicator__table}
                unit={indicator.valueInfo}
                color={indicator.color}
                values={[
                  indicator.valueFor2022,
                  indicator.valueFor2023,
                  indicator.valueFor2024,
                  indicator.valueFor2025
                ]}
              />
            )}

            {indicator.haveNested && !!indicator.nestedIndicators.length && showNested && (
              <div className={s.Indicator__nested}>
                {indicator.nestedIndicators.map((indicator) => (
                  <NestedIndicator key={indicator.id} indicator={indicator} />
                ))}
              </div>
            )}

            {curators.length > 0 && (
              <div className={s.Indicator__curators}>
                <div className={s.Indicator__curatorsTitle}>Контакты куратора</div>
                <div className={s.Indicator__curatorsList}>
                  {indicator.curators.map((item, iItem) => {
                    return (
                      <div key={iItem} className={s.IndicatorCurator}>
                        {item.name && <div className={s.IndicatorCurator__name}>{item.name || EMDASH}</div>}
                        {item.email && (
                          <Button
                            Component={'a'}
                            onClick={() => handleMailTo(`mailto:${item.email}`)}
                            variant={ButtonVariant.Link}
                            iconLeft={Link}>
                            {item.email}
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <Button className={s.Indicator__share} iconLeft={Share} variant={ButtonVariant.Link} onClick={handleShare}>
              Поделиться
            </Button>
          </Card>
        )}

        {activeTab === TabEnum.History && (
          <Card title={'История изменения'}>
            {history?.map((item) => (
              <div key={item.id} className={s.HistoryList__item}>
                <HistoryItem {...item} />
              </div>
            ))}
          </Card>
        )}
      </div>

      {positionDialog}
    </React.Fragment>
  );
}
