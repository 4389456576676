import React from 'react';
import s from './GradientRatioBar.module.scss';

export interface GradientRatioBarProps {
  relativeValue: number;
  scaleLinesCount?: number;
}

export function GradientRatioBar({ relativeValue, scaleLinesCount }: GradientRatioBarProps) {
  if (relativeValue === null || relativeValue === 0) return null;

  const scaleLines = [...Array(scaleLinesCount || 0)];
  return (
    <div className={s.GradientRatioBar}>
      <div className={s.GradientRatioBar_gradient}>
        {scaleLines?.map((el, idx) => (
          <div
            key={`scale${idx}`}
            style={{ marginLeft: `${100 / (scaleLines.length + 1)}%` }}
            className={s.GradientRatioBar_scaleLine}
          />
        ))}
      </div>
      <div className={s.GradientRatioBar__valueLine} style={{ left: `${relativeValue}%` }} />
    </div>
  );
}
