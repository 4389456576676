import React, { forwardRef } from 'react';
import clsx from 'clsx';
import s from './Tooltip.module.scss';

export enum TooltipArrowPlacement {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left'
}

export interface TooltipProps {
  children: React.ReactNode;
  // положение задается родителем
  className: string;
  arrowClassName?: string;
  arrowPlacement?: TooltipArrowPlacement;
}

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  // prettier-ignore
  function Tooltip({ children, className, arrowClassName, arrowPlacement = TooltipArrowPlacement.Top }, ref) {
    return (
      <div ref={ref} className={clsx(s.Tooltip, className)}>
        <div className={clsx(s.Tooltip__arrow, s[`Tooltip__arrow_${arrowPlacement}`], arrowClassName)} />
        <div className={s.Tooltip__content}>{children}</div>
      </div>
    );
  }
);
