import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import s from './SectionCard.module.scss';

export interface SectionCardProps extends PropsWithChildren {
  className?: string;
}

export const SectionCard = ({ className, children }: SectionCardProps) => {
  return <div className={clsx(s.SectionCard, className)}>{children}</div>;
};
